import * as React from "react";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import clsx from "clsx";

import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

type InternalOption = {
  label: React.ReactNode;
  value: null | string | number | boolean;
};

type Option = React.ReactNode | InternalOption;

type MultipleChoiceProps = {
  options?: Option[];
  selected?: InternalOption["value"][] | Set<InternalOption["value"]>;
  onChange?: (selected: InternalOption["value"]) => void;
  singleSelect?: boolean;
  disabled?: boolean;
};

const isInternalOption = (obj: any): obj is InternalOption => {
  return (
    typeof obj?.label !== "undefined" &&
    (typeof obj?.value === "string" ||
      typeof obj?.value === "number" ||
      typeof obj?.value === "boolean")
  );
};

const MultipleChoice = (
  props = {
    singleSelect: true,
    disabled: false
  } as MultipleChoiceProps
): React.ReactElement => {
  const [selected, setSelected] = useState<Set<InternalOption["value"]>>(
    new Set(props.selected)
  );
  const [options, setOptions] = useState<InternalOption[]>([]);

  useEffect(() => {
    if (!props.options || props.options.length === 0) {
      return;
    }
    if (isInternalOption(props.options[0])) {
      setOptions(props.options as InternalOption[]);
    } else {
      const newOptions = (props.options as React.ReactNode[]).map(
        (option, index) => ({
          label: option,
          value: index
        })
      );
      setOptions(newOptions);
    }
  }, [props.options]);

  useEffect(() => {
    setSelected(new Set(props.selected));
  }, [props.selected]);

  const onChange = (option: InternalOption): void => {
    if (props.singleSelect) {
      setSelected(new Set([option.value]));
    } else {
      const newSelected = new Set(selected);
      if (newSelected.has(option.value)) {
        newSelected.delete(option.value);
      } else {
        newSelected.add(option.value);
      }
      setSelected(newSelected);
    }
    props.onChange?.(option.value);
  };

  return (
    <ThemeLayout>
      <Box className="flex flex-row flex-wrap justify-left gap-2">
        {options.map((option, index) => (
          <Button
            key={index}
            className={clsx(selected.has(option.value) && "selected")}
            variant="multipleChoice"
            onClick={() => onChange(option)}
            disabled={props.disabled && !selected.has(option.value)}
          >
            <Typography variant="h4">{option.label}</Typography>
          </Button>
        ))}
      </Box>
    </ThemeLayout>
  );
};

export default MultipleChoice;
