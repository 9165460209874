import React from "react";

import colors from "../Theme/colors";

const LogoIcon = (): JSX.Element => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.271 4.59619H25.4649L14.5629 23.4916L7.66238 12.7838H10.8339L14.4762 18.6724L19.1034 10.6262C17.4619 9.42146 15.4734 8.77604 13.4293 8.78219C8.12695 8.77604 3.82191 13.0358 3.81571 18.2975C3.80952 23.5592 8.10219 27.8312 13.4046 27.8374C18.7069 27.8435 23.012 23.5838 23.0182 18.3221C23.0182 17.1357 22.7952 15.9555 22.3616 14.8491L28.271 4.59619Z"
      fill={colors.teal[20]}
    />
  </svg>
);

export default LogoIcon;
