import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";
import { getColor, hexToRgb } from "../utils";

type styleOverridesProps = any; // TODO: Improve type

const MuiButtonTheme: MuiElementTheme<"MuiButton"> = {
  defaultProps: {
    color: "primary",
    disableRipple: true,
    variant: "contained"
  },
  styleOverrides: {
    // Button - Root
    root: ({ ownerState }: styleOverridesProps) => ({
      textTransform: "none",
      borderRadius: "6px !important", // !I-BRB
      fontWeight: "700",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none"
      },
      "&.Mui-disabled": {
        opacity: 0.5
      },
      "&:focus": {
        boxShadow: `0 0 0 4px rgb(${hexToRgb(
          getColor(ownerState.color, {
            primary: colors.teal[11],
            secondary: colors.teal[11],
            error: colors.red[70]
          })
        )}, 0.5)`
      }
    }),

    // Button - Contained
    contained: ({ ownerState }: styleOverridesProps) => ({
      backgroundColor: getColor(ownerState.color, {
        primary: colors.teal[40],
        secondary: colors.teal[80]
      }),
      "&.Mui-disabled": {
        color: getColor(ownerState.color, {
          primary: `${colors.white[0]}`,
          secondary: colors.teal[30]
        }),
        background: getColor(ownerState.color, {
          primary: colors.teal[40],
          secondary: colors.teal[80]
        })
      },
      "&:hover": {
        color: getColor(ownerState.color, { secondary: colors.teal[20] }),
        backgroundColor: getColor(ownerState.color, {
          primary: colors.teal[30],
          secondary: colors.teal[70]
        })
      },
      "&:active": {
        color: getColor(ownerState.color, {
          primary: colors.teal[70],
          secondary: colors.teal[50]
        }),
        backgroundColor: getColor(ownerState.color, {
          primary: colors.teal[30],
          secondary: colors.teal[70]
        })
      }
    }),

    // Button - Outlined
    outlined: ({ ownerState }: styleOverridesProps) => ({
      borderWidth: "2px !important", // !I-BRB
      borderColor: getColor(ownerState.color, { primary: colors.teal[40] }),
      "&:hover": {
        borderColor: getColor(ownerState.color, {
          primary: colors.teal[30]
        }),
        backgroundColor: getColor(ownerState.color, {
          primary: colors.teal[80]
        })
      },
      "&:active": {
        color: getColor(ownerState.color, { primary: colors.teal[50] }),
        borderColor: getColor(ownerState.color, {
          primary: colors.teal[50]
        }),
        backgroundColor: getColor(ownerState.color, {
          primary: colors.teal[80]
        })
      },
      "&.Mui-disabled": {
        color: getColor(ownerState.color, { primary: colors.teal[40] }),
        borderColor: getColor(ownerState.color, {
          primary: colors.teal[40]
        })
      }
    }),

    // Button - Text
    text: ({ ownerState }: styleOverridesProps) => ({
      "&:hover": {
        backgroundColor: getColor(ownerState.color, {
          primary: colors.teal[80],
          error: colors.red[80]
        })
      },
      "&:active": {
        color: getColor(ownerState.color, {
          primary: colors.teal[50],
          error: colors.red[50]
        })
      },
      "&.Mui-disabled": {
        color: getColor(ownerState.color, {
          primary: colors.teal[40],
          error: colors.red[20]
        })
      }
    }),

    // Button - sizes
    sizeLarge: {
      fontSize: "16px !important", // !I-BRB
      padding: "12px 16px",
      lineHeight: "20px"
    },
    sizeSmall: {
      fontSize: "12px !important", // !I-BRB
      padding: "8px 12px",
      lineHeight: "16px"
    }
  }
};

export default MuiButtonTheme;
