import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";
import { hexToRgba } from "../utils";

export const states = {
  enabled: {
    active: {
      textIconColor: colors.teal[30],
      backgroundColor: colors.teal[90],
      barColor: colors.teal[40]
    },
    inactive: {
      textIconColor: colors.gray[30],
      backgroundColor: colors.gray[100],
      barColor: colors.gray[80]
    }
  },
  hover: {
    active: {
      textIconColor: colors.teal[20],
      backgroundColor: colors.teal[70],
      barColor: colors.teal[20]
    },
    inactive: {
      textIconColor: colors.teal[30],
      backgroundColor: colors.teal[80],
      barColor: colors.gray[80]
    }
  },
  pressed: {
    active: {
      textIconColor: colors.teal[50],
      backgroundColor: colors.teal[70],
      barColor: colors.teal[50]
    },
    inactive: {
      textIconColor: colors.teal[50],
      backgroundColor: colors.teal[80],
      barColor: colors.teal[70]
    }
  },
  focused: {
    borderColor: colors.teal[50],
    active: {
      textIconColor: colors.teal[30],
      backgroundColor: colors.teal[90],
      barColor: colors.teal[40]
    },
    inactive: {
      textIconColor: colors.gray[30],
      backgroundColor: colors.gray[100],
      barColor: colors.gray[80]
    }
  }
};

const MuiTabsTheme: MuiElementTheme<"MuiTabs"> = {
  styleOverrides: {
    root: {
      minWidth: "375px",
      ".MuiTab-root": {
        zIndex: 15,
        justifyContent: "flex-start",
        backgroundColor: states.enabled.inactive.backgroundColor,
        borderBottomWidth: "4px",
        borderBottomStyle: "solid",
        borderColor: states.enabled.inactive.barColor,
        ".MuiTypography-root, .MuiTab-iconWrapper": {
          color: states.enabled.inactive.textIconColor
        },
        ".MuiTouchRipple-child": {
          backgroundColor: states.pressed.inactive.backgroundColor,
          borderColor: states.pressed.inactive.barColor,
          ".MuiTypography-root, .MuiTab-iconWrapper": {
            color: states.pressed.inactive.textIconColor
          }
        },
        "&:hover": {
          backgroundColor: states.hover.inactive.backgroundColor,
          borderColor: states.hover.inactive.barColor,
          ".MuiTypography-root, .MuiTab-iconWrapper": {
            color: states.hover.inactive.textIconColor
          }
        },
        "&:focus": {
          backgroundColor: states.focused.inactive.backgroundColor,
          borderColor: states.focused.inactive.barColor,
          ".MuiTypography-root, .MuiTab-iconWrapper": {
            color: states.focused.inactive.textIconColor
          },
          boxShadow: `inset 0 0 0 4px ${hexToRgba(
            states.focused.borderColor,
            0.5
          )}`,

          transition: "box-shadow 0.2s ease-in-out"
        },
        "&.Mui-disabled": {
          backgroundColor: states.enabled.inactive.backgroundColor,
          borderColor: hexToRgba(states.enabled.inactive.barColor, 0.5),
          ".MuiTypography-root, .MuiTab-iconWrapper": {
            color: hexToRgba(states.enabled.inactive.textIconColor, 0.5)
          }
        },
        "&.Mui-selected": {
          backgroundColor: states.enabled.active.backgroundColor,
          borderColor: states.enabled.active.barColor,
          ".MuiTypography-root, .MuiTab-iconWrapper": {
            color: states.enabled.active.textIconColor
          },
          ".MuiTouchRipple-child": {
            backgroundColor: states.pressed.active.backgroundColor,
            borderColor: states.pressed.active.barColor,
            ".MuiTypography-root, .MuiTab-iconWrapper": {
              color: states.pressed.active.textIconColor
            }
          },
          "&:hover": {
            backgroundColor: states.hover.active.backgroundColor,
            borderColor: states.hover.active.barColor,
            ".MuiTypography-root, .MuiTab-iconWrapper": {
              color: states.hover.active.textIconColor
            }
          },
          "&:focus": {
            backgroundColor: states.focused.active.backgroundColor,
            borderColor: states.focused.active.barColor,
            ".MuiTypography-root, .MuiTab-iconWrapper": {
              color: states.focused.active.textIconColor
            },
            boxShadow: `inset 0 0 0 4px ${hexToRgba(
              states.focused.borderColor,
              0.5
            )}`,
            transition: "box-shadow 0.2s ease-in-out"
          },
          ".Mui-disabled": {
            backgroundColor: states.enabled.active.backgroundColor,
            borderColor: hexToRgba(states.enabled.active.barColor, 0.5),
            ".MuiTypography-root, .MuiTab-iconWrapper": {
              color: hexToRgba(states.enabled.active.textIconColor, 0.5)
            }
          }
        }
      },
      ".MuiTabs-indicator": {
        backgroundColor: states.enabled.active.barColor,
        height: "4px"
      },
      ".MuiButtonBase-root": {
        textTransform: "none"
      }
    }
  }
};

export default MuiTabsTheme;
