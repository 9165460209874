import { getColor } from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    teal: true;
    gold: true;
    coral: true;
    gray: true;
    red: true;
    yellow: true;
    green: true;
    blue: true;
    grape: true;
    guava: true;
    tangerine: true;
    lemon: true;
    lime: true;
    ocean: true;
    berry: true;
    plum: true;
    coconut: true;
    carrot: true;
  }
}

const MuiAlertTheme: MuiElementTheme<"MuiAlert"> = {
  defaultProps: {
    color: "gray"
  },
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      minHeight: "48px",
      borderWidth: "2px",
      borderRadius: "6px",
      borderColor: getColor(ownerState.color, 50),
      backgroundColor: getColor(ownerState.color, 80),
      alignItems: "center",
      gap: "6px",
      padding: "12px",
      "& .MuiAlert-message, & .MuiAlert-icon": {
        color: getColor(ownerState.color, 20),
        height: "fit-content",
        margin: "0px",
        padding: "0px"
      }
    })
  }
};

export default MuiAlertTheme;
