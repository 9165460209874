import { ISODateTime } from "./common";
import {
  IdentificationEmployee,
  IdentificationEmployeeReference
} from "./employees";
import { APIResponse, destroy, get, patch, post } from "./lib";

export interface MessageContext {
  email_subject: string;
  email_body: string;
  sms_content: string;
}

export type MessageRecipientKind = "com" | "div" | "grp" | "emp" | "rpt";

export interface EmployeeMessageRecipient {
  kind: "emp" | "rpt";
  values: IdentificationEmployee[];
}

export interface EmployeeMessageRecipientWriter {
  kind: "emp" | "rpt";
  values: IdentificationEmployeeReference[];
}

export interface GenericMessageRecipient {
  kind: Exclude<MessageRecipientKind, "emp" | "rpt">;
  values: IdObject[];
}

interface IdObject {
  id: string;
}

export interface ScheduledMessageTest {
  context_json: MessageContext;
}

export type MessageRecipient =
  | EmployeeMessageRecipient
  | GenericMessageRecipient;

export type MessageRecipientWriter =
  | EmployeeMessageRecipientWriter
  | GenericMessageRecipient;

export interface ScheduledMessageWriter {
  name: string;
  due: ISODateTime;
  context_json: MessageContext;
  recipients_json: MessageRecipientWriter[];
}

export interface ScheduledMessageUpdater extends ScheduledMessageWriter {
  id: string;
}

export interface ScheduledMessage
  extends Omit<ScheduledMessageUpdater, "recipients_json"> {
  recipients_json: MessageRecipient[];
  created_by: IdentificationEmployee;
}

export interface EmployeeMessage {
  due: ISODateTime;
  context_json: MessageContext;
}

export const createScheduledMessage = async (
  message: ScheduledMessageWriter
): Promise<APIResponse<ScheduledMessage>> => {
  return await post(`/messaging/messages/`, message);
};

export const createTestMessage = async (
  message: ScheduledMessageTest
): Promise<APIResponse<ScheduledMessage>> => {
  return await post(`/messaging/send_test/`, message);
};

export const retrieveScheduledMessages = async (options?: {
  records_limit?: number;
}): Promise<APIResponse<ScheduledMessage[]>> => {
  return await get(`/messaging/messages/`, {
    expanded_detail: "1",
    ...(options?.records_limit
      ? { records_limit: `${options.records_limit}` }
      : {})
  });
};

export const retrieveScheduledMessage = async (
  messageId: string
): Promise<APIResponse<ScheduledMessage>> => {
  return await get(`/messaging/messages/${messageId}/`, {
    expanded_detail: "1"
  });
};

export const updateScheduledMessage = async (
  message: ScheduledMessageUpdater
): Promise<APIResponse<ScheduledMessage>> => {
  return await patch(`/messaging/messages/${message.id}/`, message);
};

export const deleteScheduledMessage = async (
  messageId: string
): Promise<APIResponse<{}>> => {
  return await destroy(`/messaging/messages/${messageId}/`);
};

export interface SentMessage extends ScheduledMessage {
  sent_id?: string;
  sent_at?: ISODateTime;
}

export const retrieveSentMessages = async (options?: {
  records_limit?: number;
}): Promise<APIResponse<SentMessage[]>> => {
  return await get(`/messaging/sent_messages/`, {
    expanded_detail: "1",
    ...(options?.records_limit
      ? { records_limit: `${options.records_limit}` }
      : {})
  });
};

export interface SentMessageLog {
  id: string;
  kind: "sms" | "email";
  receiver: string | null;
  receiver_employee: IdentificationEmployee | null;
}

export interface SentMessageDetail extends SentMessage {
  message_logs: SentMessageLog[];
}

export interface Count {
  count: number;
}

export const retrieveSentMessage = async (
  messageId: string
): Promise<APIResponse<SentMessageDetail>> => {
  return await get(`/messaging/sent_messages/${messageId}/`);
};

export const countMessageRecipients = async (
  recipients: MessageRecipientWriter[]
): Promise<APIResponse<Count>> => {
  return await post(`/messaging/count-recipients/`, recipients);
};

export const retrieveEmployeeMessage = async (
  token: string
): Promise<APIResponse<EmployeeMessage>> => {
  return await get(`/messaging/employee_message/`, undefined, {
    "X-EmployeeTokenSession": token
  });
};
