import * as React from "react";
import { useState } from "react";

import ButtonBase from "@mui/material/ButtonBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  default as MuiSelect,
  SelectChangeEvent,
  SelectProps
} from "@mui/material/Select";
import { CaretDown, CaretUp, IconWeight } from "@phosphor-icons/react";
import clsx from "clsx";

import { adaptProps } from "../mui-stories-utils";
import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";

type Item = {
  value: number | string | boolean;
  label: React.ReactElement | string;
};

const Select = (
  componentProps: SelectProps & {
    items: Item[] | string[];
    onChange: (value: Item["value"]) => void;
  }
): React.ReactElement<SelectProps> => {
  const { className, props } = adaptProps(componentProps);
  const [open, setOpen] = useState(false);
  const selectId = props.id || "select";

  const onChange = (event: SelectChangeEvent<unknown>): void => {
    if (props.onChange) {
      props.onChange(event.target.value as Item["value"]);
    }
  };

  const SelectIcon = ({ className }: { className: string }): JSX.Element => {
    const isOpen = className.includes("MuiSelect-iconOpen");
    const iconProps = {
      color: colors.teal[40],
      size: 20,
      weight: "bold" as IconWeight,
      style: {
        marginRight: "10px"
      }
    };
    if (isOpen) {
      return (
        <ButtonBase>
          <CaretUp {...iconProps} />
        </ButtonBase>
      );
    } else {
      return (
        <ButtonBase>
          <CaretDown {...iconProps} />
        </ButtonBase>
      );
    }
  };

  return (
    <ThemeLayout>
      <FormControl fullWidth>
        {props.label && (
          <InputLabel id={`${selectId}-label`}> {props.label}</InputLabel>
        )}
        <MuiSelect
          id={`${selectId}-label`}
          {...props}
          onChange={onChange}
          className={clsx(className)}
          IconComponent={SelectIcon}
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiButtonBase-root": {
                  fontSize: 12
                }
              }
            }
          }}
        >
          {props.items.map((item: any, index: number) => (
            <MenuItem key={index} value={item.value || item}>
              {item.label || item}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </ThemeLayout>
  );
};

export default Select;
