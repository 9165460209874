import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

const MuiSelectTheme: MuiElementTheme<"MuiSelect"> = {
  defaultProps: {},
  styleOverrides: {
    root: {
      minWidth: "100px",
      color: colors.teal[40],
      borderColor: colors.teal[40],
      "& .MuiSelect-select": {
        fontSize: "0.75rem",
        fontWeight: 700,
        paddingRight: "0px !important"
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.teal[40]
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.teal[40]
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.teal[40]
      },
      height: "32px"
    }
  }
};

export default MuiSelectTheme;
