import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

type GenericErrorTextProps = {
  isFormError?: boolean;
};

export const GenericErrorText: FunctionComponent<GenericErrorTextProps> = ({
  isFormError
}) => {
  const supportEmail = "support@teamsense.com";
  const supportEmailLink = (
    <a href={`mailto:${supportEmail}`} style={{ textDecoration: "underline" }}>
      {supportEmail}
    </a>
  );
  const genericError = (
    <Trans id="error.generic" values={{ supportEmailLink }}>
      Oops, something went wrong. Please try again and contact
      {supportEmailLink} if the issue continues.
    </Trans>
  );
  const genericFormError = (
    <Trans id="error.form.generic" values={{ supportEmailLink }}>
      Invalid response, please check your answer and try again. Contact
      {supportEmailLink} if the issue continues.
    </Trans>
  );
  return <div dir="auto">{isFormError ? genericFormError : genericError}</div>;
};
