import "core-js";
import "whatwg-fetch";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { polyfill } from "seamless-scroll-polyfill";

import "./googleTranslatePatch";
import App from "./App";
import { msalInstance } from "./lib/auth";
import ErrorFallback from "./mui-components/ErrorFallback/ErrorFallback";

type EnvTypes =
  | "production"
  | "development"
  | "test"
  | "staging"
  | "review"
  | "dev-docker";

// Sentry route component
const history = createBrowserHistory();

const getURLHost = (url: string): string => {
  const parsedURL = new URL(url);
  return parsedURL.host;
};
const allowedScriptURLHosts = ["cdn.pendo.io", "edge.fullstory.com"];
const isAllowedScriptURL = (url: string): boolean => {
  const host = getURLHost(url);
  return allowedScriptURLHosts.includes(host);
};

window.trustedTypes?.createPolicy("default", {
  createScriptURL: input => {
    if (isAllowedScriptURL(input)) {
      return input;
    }
    throw new Error(`Blocked unsafe script URL: ${input}`);
  },
  createScript: input => {
    console.log("Logging createScript", input);
    return input;
  }
});

if (process.env.REACT_APP_SENTRY_DSN) {
  console.log("Initializing Sentry");
  let sampleRate = 0.1;
  if (process.env.REACT_APP_APP_ENV !== "production") {
    sampleRate = 1.0;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    environment: process.env.REACT_APP_APP_ENV,
    tracesSampleRate: sampleRate,
    release: `teamsense-cra@${process.env.REACT_APP_SOURCE_VERSION}`
  });
} else {
  console.log("NOT Initializing Sentry: DSN not found");
}

function setFavicon(env: string): void {
  let colorSuffix = "";
  if (env === "staging") {
    colorSuffix = "-orange";
  }
  if (env === "test" || env === "review") {
    colorSuffix = "-pink";
  }
  // cra app is blue in dev
  if (env === "development" || env === "dev-docker") {
    colorSuffix = "-blue";
  }

  const favicon = document.getElementById("favicon");
  favicon?.setAttribute("href", `/favicon${colorSuffix}.ico`);
}

const appEnv = process.env.REACT_APP_APP_ENV as string as EnvTypes;
setFavicon(appEnv);

polyfill({});

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(
    <MsalProvider instance={msalInstance()}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <BrowserRouter>
          <Switch>
            <Route
              path={[
                "/manager/directs/:employeeSessionToken",
                "/c/:conversationToken",
                "/s/:conversationToken",
                "/s/:conversationToken/attendance-history",
                "/s/:conversationToken/balances",
                "/portal/:employeeSessionToken",
                "/menu/:employeeSessionToken",
                "/attendance/:employeeSessionToken",
                "/m/:employeeSessionToken",
                "/screening/:employeeSessionToken",
                "/"
              ]}
            >
              <App />
            </Route>
          </Switch>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </MsalProvider>
  );
} else {
  console.debug("Not an SPA page");
}
