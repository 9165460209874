import * as React from "react";

import { default as MuiAlert, AlertProps } from "@mui/material/Alert";
import { Warning } from "@phosphor-icons/react";

import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

const Alert = (
  props: AlertProps & { icon?: React.ReactNode }
): React.ReactElement<AlertProps> => {
  const predefinedTypes: {
    [key: string]: {
      color: string | undefined;
      icon: React.ReactNode | undefined;
    };
  } = {
    warning: {
      color: "yellow",
      icon: <Warning />
    },
    error: {
      color: "red",
      icon: undefined
    },
    info: {
      color: "blue",
      icon: undefined
    },
    success: {
      color: "green",
      icon: undefined
    }
  };

  const getIcon = (): React.ReactNode => {
    if (props.icon) {
      return props.icon;
    }
    if (props.severity && predefinedTypes[props.severity]) {
      return predefinedTypes[props.severity].icon;
    }
  };

  const getColor = (): AlertProps["color"] => {
    if (props.severity && predefinedTypes[props.severity]) {
      return predefinedTypes[props.severity].color as AlertProps["color"];
    }
    if (props.color) {
      return props.color;
    }
    return "teal";
  };

  return (
    <ThemeLayout>
      <MuiAlert {...props} icon={getIcon()} color={getColor()}>
        <Typography variant="h3">{props.children}</Typography>
      </MuiAlert>
    </ThemeLayout>
  );
};

export default Alert;
