import * as React from "react";
import { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {
  default as MuiTextField,
  TextFieldProps
} from "@mui/material/TextField";
import {
  CalendarBlank,
  CaretDown,
  Warning,
  XCircle
} from "@phosphor-icons/react";
import clsx from "clsx";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";

export type Action = "clear" | "calendar" | "select";

const TextField = (
  componentProps: TextFieldProps & { action?: Action }
): React.ReactElement<TextFieldProps> => {
  const { className, props } = adaptProps(componentProps);

  const [value, setValue] = useState<string>(props.value);
  const [hovered, setHovered] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);

  useEffect(() => {
    if (props.disabled) {
      setRequired(false);
    } else {
      setRequired(Boolean(props.required));
    }
  }, [props.disabled, props.required]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const IconActions = {
    Clear: (): React.ReactElement => {
      const resetValue = (): void => {
        setValue("");
        props.onChange?.({
          target: { value: "" }
        } as React.ChangeEvent<HTMLInputElement>);
      };
      const isNoAction = props.error || props.disabled;
      const icon = props.error ? (
        <Warning size={20} className="text-ts-red-20" weight="fill" />
      ) : (
        <XCircle
          size={20}
          className={clsx(hovered ? "text-ts-gray-30" : "text-ts-gray-40")}
        />
      );
      return (
        <IconButton
          onClick={() => (isNoAction ? undefined : resetValue())}
          disabled={isNoAction}
          edge="end"
        >
          {icon}
        </IconButton>
      );
    },
    Calendar: (): React.ReactElement => {
      const showCalendar = (): void => {
        console.warn("Not implemented yet");
      };
      return (
        <IconButton
          onClick={() => (props.disabled ? undefined : showCalendar())}
          disabled={props.disabled}
          edge="end"
        >
          <CalendarBlank size={20} />
        </IconButton>
      );
    },
    Select: (): React.ReactElement => {
      const showDropdown = (): void => {
        console.warn("Not implemented yet");
      };
      return (
        <IconButton
          onClick={() => (props.disabled ? undefined : showDropdown())}
          disabled={props.disabled}
          edge="end"
        >
          <CaretDown size={20} />
        </IconButton>
      );
    }
  };

  const iconActionObject: Record<Action, () => React.ReactElement> = {
    clear: IconActions.Clear,
    calendar: IconActions.Calendar,
    select: IconActions.Select
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    props.onChange?.(e);
  };

  const onHover = (hover: boolean): void => {
    if (props.disabled) {
      setHovered(false);
    } else {
      setHovered(hover);
    }
  };

  const inputProps = {
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end">
        {props.action && iconActionObject[props.action as Action]()}
      </InputAdornment>
    )
  };

  const getLabel = (): TextFieldProps["label"] => {
    if (props.label) {
      return props.label;
    }
    if (props.required) {
      return "Required Field";
    }
    return "";
  };

  return (
    <ThemeLayout>
      <MuiTextField
        {...props}
        variant="filled"
        className={clsx(
          className,
          hovered && "hovered",
          props.multiline && "multiline",
          !props.label && "no-label"
        )}
        label={getLabel()}
        value={value}
        onChange={onChange}
        required={required}
        InputProps={inputProps}
        // slotProps={{input: inputProps}}
        // InputProps will be depreacted in MUI v7
        // slotProps will be used instead from MUI V6
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      />
    </ThemeLayout>
  );
};

export default TextField;
