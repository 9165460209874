import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    textBubble: true;
  }
}

const MuiTypographyTheme: MuiElementTheme<"MuiTypography"> = {
  variants: [
    {
      props: { variant: "textBubble" },
      style: {
        color: colors.teal[12],
        fontWeight: 600,
        display: "inline-block",
        lineHeight: "1.25rem",
        padding: "0.75rem",
        backgroundColor: colors.gray[80],
        borderRadius: "0.5rem",
        "&.use-border": {
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: colors.gray[40],
          borderRadius: "0.25rem"
        },
        "&.use-teal": {
          borderColor: colors.teal[30],
          backgroundColor: colors.teal[80],
          color: colors.teal[30]
        }
      }
    }
  ],
  defaultProps: {
    letterSpacing: "0.5px"
  },
  styleOverrides: {
    root: {
      marginBottom: "0px !important" // !I-BRB
    },
    h1: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: 700
    },
    h3: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
      fontWeight: 500
    },
    h6: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      fontWeight: 700,
      textTransform: "uppercase"
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "1rem"
    }
  }
};

export default MuiTypographyTheme;
