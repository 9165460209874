import * as React from "react";

import {
  getGridSingleSelectOperators,
  GridColDef,
  GridFilterModel,
  GridHeaderFilterCellProps
} from "@mui/x-data-grid-pro";

import {
  MemorizedCellLink,
  MemorizedReactivateButton,
  MemorizedRightArrow,
  MemorizedStatusPills
} from "./CustomGridSlots";
import EmployeeTableSelectFilter from "./filters";

import { Group } from "~/api";
import * as api from "~/api";
import { EmployeeFilterActions, FilterListName } from "~/lib/employeesList";

export enum EmployeeTableHeaders {
  FirstName = "firstName",
  LastName = "lastName",
  Name = "name",
  ExternalId = "externalId",
  Status = "status",
  Division = "division",
  Manager = "manager",
  Groups = "groups",
  Arrow = "_arrow",
  Reactivate = "_reactivate"
}

type Filter = {
  dispatch: (value: EmployeeFilterActions) => void;
  options: { id: string; name: string }[];
  dispatchListName: string;
  initialState: GridFilterModel | undefined;
  noOptionText: string;
};

const gridHeaders = (
  headerName: string,
  props?: {
    filter?: Filter;
    session?: api.Session;
    language?: string;
    onEditStatusClick?: (employee: api.Employee, labelKey: string) => void;
    employee?: api.Employee;
    onReactivateClick?: (employee: api.Employee) => void;
  }
): GridColDef => {
  const basicHeaderProps: Omit<GridColDef, "type" | "field"> = {
    renderCell: params => {
      const { value } = params;
      return value;
    },
    minWidth: 80,
    flex: 1,
    filterable: true,
    sortable: true
  };
  const headers: { [key: string]: GridColDef } = {
    firstName: {
      ...basicHeaderProps,
      filterable: false,
      field: "given_name",
      headerName: "First name"
    },
    lastName: {
      ...basicHeaderProps,
      filterable: false,
      field: "family_name",
      headerName: "Last name"
    },
    name: {
      ...basicHeaderProps,
      filterable: false,
      flex: 2,
      field: "name",
      headerName: "Employee"
    },
    externalId: {
      ...basicHeaderProps,
      filterable: false,
      field: "external_id",
      headerName: "Employee ID"
    },
    status: {
      ...basicHeaderProps,
      field: "active_events",
      headerName: "Status",
      sortable: false,
      filterOperators: getGridSingleSelectOperators(),
      renderCell: params => {
        return (
          <MemorizedStatusPills
            activeEvents={params.value ?? []}
            labels={(props?.session?.labels || []) as api.LabelInfoMap}
            language={props?.language ?? "en-US"}
            employee={params.row as api.Employee}
            onEditStatusClick={props?.onEditStatusClick}
          />
        );
      }
    },
    division: {
      ...basicHeaderProps,
      field: "division",
      headerName: "Division",
      sortable: false,
      filterOperators: getGridSingleSelectOperators(),
      renderCell: params => {
        const divisionName = params.value?.name;
        return divisionName;
      }
    },
    manager: {
      ...basicHeaderProps,
      flex: 2,
      field: "team_lead",
      headerName: "Manager",
      sortable: false,
      filterOperators: getGridSingleSelectOperators(),
      renderCell: params => {
        const teamLeadName = params.value?.name;
        const teamLeadId = params.value?.id;
        return <MemorizedCellLink id={teamLeadId} name={teamLeadName} />;
      }
    },
    groups: {
      ...basicHeaderProps,
      field: "groups",
      headerName: "Groups",
      sortable: false,
      filterOperators: getGridSingleSelectOperators(),
      renderCell: params => {
        const groups =
          params.value?.map((group: Group) => group.name).join(", ") || "";
        return groups;
      }
    },
    _arrow: {
      minWidth: 40,
      maxWidth: 40,
      field: "_arrow",
      headerName: "",
      type: "custom",
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      groupable: false,
      renderCell: () => <MemorizedRightArrow />
    },
    _reactivate: {
      minWidth: 120,
      field: "_reactivate",
      headerName: "",
      type: "custom",
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      groupable: false,
      renderCell: params => (
        <MemorizedReactivateButton
          employee={params.row as api.Employee}
          onReactivateClick={props?.onReactivateClick}
        />
      )
    }
  };

  const filter = (params: GridHeaderFilterCellProps & Filter): JSX.Element => (
    <EmployeeTableSelectFilter
      {...params}
      noOptionText={props?.filter?.noOptionText}
      initialState={props?.filter?.initialState}
      onModelChange={(items, displayEmpty) => {
        if (items.length === 0 && !displayEmpty) {
          props?.filter?.dispatch({
            type: "clearFilter",
            listName: props?.filter?.dispatchListName as FilterListName
          });
        } else {
          props?.filter?.dispatch({
            type: "updateVisibleByFilterItems",
            listName: props?.filter?.dispatchListName as FilterListName,
            items,
            displayEmpty
          });
        }
      }}
      options={(props?.filter?.options || []).map(status => ({
        value: status.id,
        label: status.name
      }))}
    />
  );

  const header = headers[headerName];
  if (!header) {
    return {
      field: headerName,
      headerName,
      renderCell: params => {
        const { value } = params;
        return value;
      }
    };
  }
  if (props?.filter) {
    header.renderHeaderFilter = headerProps =>
      filter({ ...headerProps, ...(props.filter as Filter) });
  }
  return headers[headerName];
};

export default gridHeaders;
