import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import { Trans } from "@lingui/react";
import {
  CalendarBlank,
  ClipboardText,
  SquaresFour
} from "@phosphor-icons/react";

import { PreSurvey } from "./PreSurvey";

import * as api from "~/api";
import { PortalList, SurveyList } from "~/components";
import { MutableSessionContext } from "~/lib/context";
import useChangeLanguage from "~/lib/hooks/useChangeLanguage";
import HeaderEmployeeExperience from "~/mui-components/HeaderEmployeeExperience/HeaderEmployeeExperience";
import Tabs from "~/mui-components/Tabs/Tabs";
import { useSource } from "~/ts-components/common/auth/SourceWrapper";
import { ErrorExpiredLink } from "~/ts-components/common/errors/ErrorExpiredLink";
import { Loading, GenericErrorText } from "~common";

interface ParamTypes {
  token: string;
}

enum PortalTab {
  PORTAL = "portal",
  FORMS = "menu",
  ATTENDANCE = "attendance"
}

const tabs = [
  {
    key: PortalTab.ATTENDANCE,
    label: <Trans id="attendance.list.title">Attendance</Trans>,
    disabled: false,
    icon: <CalendarBlank size={32} />
  },
  {
    key: PortalTab.PORTAL,
    label: <Trans id="portal.list.title">Portal</Trans>,
    icon: <SquaresFour size={32} />,
    disabled: false
  },
  {
    key: PortalTab.FORMS,
    label: <Trans id="survey.list.title">Forms</Trans>,
    icon: <ClipboardText size={32} />,
    disabled: false
  }
];

const Content: FunctionComponent<{
  activeTab: PortalTab;
  portalLinks?: api.PortalLink[];
  surveyTemplates?: api.SurveyTemplate[];
  requestingSurvey: boolean;
  tokenType: string | undefined;
  triggerSurvey: boolean;
  absenceSurveyTemplate: api.SurveyTemplate | null | undefined;
  goToSurvey: (show: boolean) => void;
  onClickLink: (portalLink: api.PortalLink) => void;
  onRequestSurvey: (template: api.SurveyTemplate) => Promise<void>;
}> = ({
  activeTab,
  portalLinks,
  surveyTemplates,
  requestingSurvey,
  tokenType,
  triggerSurvey,
  absenceSurveyTemplate,
  goToSurvey,
  onClickLink,
  onRequestSurvey
}) => {
  if (activeTab === PortalTab.PORTAL) {
    if (portalLinks) {
      return <PortalList portalLinks={portalLinks} onClick={onClickLink} />;
    }
    return <Loading />;
  }
  if (activeTab === PortalTab.FORMS) {
    if (surveyTemplates) {
      return (
        <SurveyList templates={surveyTemplates} onClick={onRequestSurvey} />
      );
    }
    return <Loading />;
  }
  if (activeTab === PortalTab.ATTENDANCE) {
    if (!requestingSurvey) {
      return (
        <PreSurvey
          isValidEmployeeToken={tokenType === "PORTAL"}
          onSetShowAttendanceHistory={goToSurvey}
          triggerSurvey={triggerSurvey}
          hideHeaders={true}
          showReportAbsenceButton={
            tokenType === "SURVEY" || Boolean(absenceSurveyTemplate)
          }
        />
      );
    }
    return <Loading />;
  }
  return null;
};

export const EmployeePortal: FunctionComponent = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const source = useSource();
  const changeLanguage = useChangeLanguage();

  const getInitialTab = (): PortalTab => {
    if (match.path.startsWith("/portal")) {
      return PortalTab.PORTAL;
    } else if (match.path.startsWith("/menu")) {
      return PortalTab.FORMS;
    } else if (match.path.startsWith("/attendance")) {
      return PortalTab.ATTENDANCE;
    } else {
      return PortalTab.PORTAL;
    }
  };

  const { session } = useContext(MutableSessionContext);
  const [surveyTemplates, setSurveyTemplates] =
    useState<api.SurveyTemplate[]>();
  const [portalLinks, setPortalLinks] = useState<api.PortalLink[]>();
  const [requestingSurvey, setRequestingSurvey] = useState(false);
  const [triggerSurvey, setTriggerSurvey] = useState(false);
  const [tokenType, setTokenType] = useState<
    "SURVEY" | "PORTAL" | "" | undefined
  >();
  const { token: paramToken } = useParams<ParamTypes>();
  const [token, setToken] = useState(paramToken);
  const [activeTab, setActiveTab] = useState<PortalTab>(getInitialTab());
  const [absenceSurveyTemplate, setAbsenceSurveyTemplate] = useState<
    api.SurveyTemplate | undefined | null
  >(undefined);
  const { languages = [] } = session.company ?? {};

  const changeTab = (tabKey: PortalTab, newToken?: string): void => {
    if (tabKey === activeTab && !newToken) {
      return;
    }
    setActiveTab(tabKey);
    const queryParams = source.rawQueryParams && `?${source.rawQueryParams}`;
    if (!newToken) {
      // Moving between tabs, no need to change the history
      history.replace(`/${tabKey}/${token}${queryParams}`);
    } else {
      // Moving between survey and portal, updating the history
      history.push(`/${tabKey}/${newToken}${queryParams}`);
    }
  };

  const toastGenericError = (options?: ToastOptions): void => {
    toast.error(<GenericErrorText />, options);
  };

  const onClickLink = (portalLink: api.PortalLink): void => {
    window.location.assign(portalLink.url);
  };

  const onRequestSurvey = async (
    template: api.SurveyTemplate
  ): Promise<void> => {
    setRequestingSurvey(true);

    let response: api.APIResponse<api.SurveyLink> | undefined;
    try {
      response = await api.createSurveyFromMenu(token, template.id);
    } catch {}

    if (!response?.ok) {
      toastGenericError();
      return;
    }

    setTriggerSurvey(true);
    setToken(response.data.token);
    setTokenType("SURVEY");
    changeTab(PortalTab.ATTENDANCE, response.data.token);
    setRequestingSurvey(false);
  };

  useEffect(() => {
    // We don't need to check for timing attacks here, these tokens are public
    // eslint-disable-next-line security/detect-possible-timing-attacks
    if (paramToken !== token) {
      setToken(paramToken);
      setTokenType(undefined);
    }
  }, [paramToken, token]);

  useEffect(() => {
    const requestPortalLinks = async (companyId: string): Promise<boolean> => {
      let response: api.APIResponse<api.PortalLink[]> | undefined;
      try {
        response = await api.retrievePortalLinks(companyId, { token });
      } catch {}

      if (response?.ok) {
        setPortalLinks(response.data);
        return true;
      } else if (response?.status !== 403) {
        toastGenericError();
      }
      return false;
    };

    const requestSurveyTemplates = async (
      employeeId: string,
      companyId: string
    ): Promise<boolean> => {
      let response: api.APIResponse<api.SurveyMenuResponse> | undefined;
      try {
        response = await api.retrieveSurveyTemplatesMenu(
          companyId,
          employeeId,
          { token }
        );
      } catch {}

      if (response?.ok) {
        setSurveyTemplates(response.data.data);
        return true;
      } else if (response?.status !== 403) {
        toastGenericError();
      }
      return false;
    };

    const requestSurveyToken = async (token: string): Promise<boolean> => {
      try {
        const response = await api.getSurveyState(token, {
          noStart: true
        });
        if (response?.ok) {
          changeTab(PortalTab.ATTENDANCE);
          return true;
        }
      } catch {}
      return false;
    };

    const checkForAbsenceSurvey = async (
      employeeId: string,
      companyId: string
    ): Promise<void> => {
      const response = await api.retrieveAbsenceSurveyTemplate(
        companyId,
        employeeId,
        { token }
      );
      if (response?.ok) {
        setAbsenceSurveyTemplate(response.data.data);
      } else {
        setAbsenceSurveyTemplate(null);
      }
    };

    const asyncUseEffectWrapper = async (): Promise<void> => {
      if (!session.company || !session.employee || !token) {
        setTokenType("");
        return;
      }
      if (await requestSurveyToken(token)) {
        setTokenType("SURVEY");
      } else if (
        (await requestPortalLinks(session.company.id)) &&
        (await requestSurveyTemplates(session.employee.id, session.company.id))
      ) {
        setTokenType("PORTAL");
      } else {
        setTokenType("");
      }
      checkForAbsenceSurvey(session.employee.id, session.company.id);
    };
    asyncUseEffectWrapper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.company, session.employee, token]);

  const goToSurvey = (_: boolean): void => {
    if (tokenType === "PORTAL" && absenceSurveyTemplate) {
      onRequestSurvey(absenceSurveyTemplate);
    }
  };

  if (tokenType === undefined || absenceSurveyTemplate === undefined) {
    return <Loading />;
  }

  // eslint-disable-next-line security/detect-possible-timing-attacks
  if (paramToken !== token) {
    return <Loading />;
  }

  if (tokenType === "") {
    return <ErrorExpiredLink />;
  }

  return (
    <div className="flex flex-col h-screen">
      {tokenType === "PORTAL" && (
        <div>
          <HeaderEmployeeExperience
            companyName={session.company?.name}
            languageOptions={languages}
            onChangeLanguage={changeLanguage}
            languageSelectorId="language-selector-employee-experience"
          />
          <Tabs
            tabs={tabs}
            selectedTab={activeTab}
            setSelectedTab={tabKey => changeTab(tabKey as PortalTab)}
          ></Tabs>
        </div>
      )}
      <Content
        activeTab={activeTab}
        portalLinks={portalLinks}
        surveyTemplates={surveyTemplates}
        requestingSurvey={requestingSurvey}
        tokenType={tokenType}
        triggerSurvey={triggerSurvey}
        absenceSurveyTemplate={absenceSurveyTemplate}
        goToSurvey={goToSurvey}
        onClickLink={onClickLink}
        onRequestSurvey={onRequestSurvey}
      />
    </div>
  );
};
