import {
  FilterListItem,
  SelectedItem,
  FilterList,
  SelectedItems
} from "./filterList";

import * as api from "~/api";

const populateAllEmployeeIds = (
  employees: api.Employee[],
  nextUrl: string | null
): string[] | undefined => {
  if (!nextUrl) {
    return employees.map(({ id }) => id);
  }
};

const updateAllSelectedItems = (
  items: SelectedItems<FilterListItem>,
  selected: boolean | string[]
): any =>
  Object.fromEntries(
    Object.entries(items).map(([key, value]) => [
      key,
      {
        ...value,
        selected: Array.isArray(selected) ? selected.includes(key) : selected
      }
    ])
  );

export const initialDisplayState = (
  fullCovidHealthStatuses: boolean,
  labelInfoMap: api.LabelInfoMap,
  language: string,
  statusFilterValues?: InitialStatusFilterValues
): EmployeeDisplayList => {
  // TODO: localize (None) once admin UX is localized
  const generateInitialSelectedItem = (
    key: api.AnyStatusPlusNone
  ): SelectedItem<FilterListItem> => ({
    selected: statusFilterValues?.includes(key) ?? true,
    item: {
      id: key,
      name: labelInfoMap[key] ? labelInfoMap[key].display[language] : "(None)"
    }
  });

  const labelKeys = Object.keys(labelInfoMap);
  if (!fullCovidHealthStatuses) {
    labelKeys.push("None");
  }

  return {
    loading: true,
    employees: [],
    loadingEmployeeIds: false,
    selectedEmployeeIds: [],
    nextUrl: null,
    count: 0,
    activeStatuses: {
      displayEmpty: true,
      items: labelKeys.reduce(
        (object, key) => ({
          ...object,
          [key]: generateInitialSelectedItem(key as api.AnyStatus)
        }),
        {}
      )
    },
    activeGroups: {
      displayEmpty: true,
      items: {}
    },
    activeManagers: {
      displayEmpty: true,
      items: {}
    },
    activeDivisions: {
      displayEmpty: true,
      items: {}
    }
  };
};

export const employeeDisplayReducer = (
  state: EmployeeDisplayList,
  action: EmployeeFilterActions
): EmployeeDisplayList => {
  const updateOrRemoveOneEmployee = (
    index: number,
    state: EmployeeDisplayList,
    updatedEmployee?: api.Employee
  ): EmployeeDisplayList => {
    const { employees } = state;
    return {
      ...state,
      employees: [
        ...employees.slice(0, index),
        ...(updatedEmployee ? [updatedEmployee] : []),
        ...employees.slice(index + 1)
      ]
    };
  };

  switch (action.type) {
    case "selectOne": {
      const employeeId = state.employees[action.index].id;
      if (action.newValue) {
        if (state.selectedEmployeeIds.includes(employeeId)) {
          return state;
        }
        return {
          ...state,
          selectedEmployeeIds: [...state.selectedEmployeeIds, employeeId]
        };
      } else {
        return {
          ...state,
          selectedEmployeeIds: state.selectedEmployeeIds.filter(
            id => id !== employeeId
          )
        };
      }
    }
    case "selectNone":
      return {
        ...state,
        selectedEmployeeIds: []
      };
    case "selectAll":
      return {
        ...state,
        selectedEmployeeIds: state.allEmployeeIds
          ? [...state.allEmployeeIds]
          : state.selectedEmployeeIds
      };
    case "selectSome":
      return {
        ...state,
        selectedEmployeeIds: action.selectedEmployeeIds
      };
    case "setLoadingEmployeeIds":
      return {
        ...state,
        loadingEmployeeIds: action.newValue
      };
    case "setAllEmployeeIdsAndSelectAll":
      return {
        ...state,
        loadingEmployeeIds: false,
        allEmployeeIds: [...action.allEmployeeIds],
        selectedEmployeeIds: [...action.allEmployeeIds]
      };
    case "updateEmployee": {
      const index = state.employees.findIndex(
        employee => employee.id === action.updatedEmployee.id
      );
      if (index === -1) {
        return state;
      }
      return updateOrRemoveOneEmployee(index, state, action.updatedEmployee);
    }
    case "loadDivisions":
      return {
        ...state,
        activeDivisions: { ...action.newState.activeDivisions }
      };
    case "loadGroupsAndLeads":
      return {
        ...state,
        activeGroups: { ...action.newState.activeGroups },
        activeManagers: { ...action.newState.activeManagers }
      };
    case "requesting":
      return {
        ...state,
        loading: true
      };
    case "setEmployees": {
      const allEmployeeIds = populateAllEmployeeIds(
        action.newEmployees,
        action.nextUrl
      );
      return {
        ...state,
        loading: false,
        employees: action.newEmployees,
        selectedEmployeeIds: [],
        allEmployeeIds,
        nextUrl: action.nextUrl,
        count: action.count
      };
    }
    case "addEmployees": {
      const employees = [...state.employees, ...action.newEmployees];
      const allEmployeeIds =
        state.allEmployeeIds ??
        populateAllEmployeeIds(employees, action.nextUrl);
      return {
        ...state,
        loading: false,
        employees,
        allEmployeeIds,
        nextUrl: action.nextUrl,
        count: action.count
      };
    }
    case "removeEmployee": {
      const index = state.employees.findIndex(
        employee => employee.id === action.removedEmployee.id
      );
      if (index === -1) {
        return state;
      }
      return updateOrRemoveOneEmployee(index, state);
    }
    case "updateVisibleByFilterItem":
      state[action.listName] = {
        displayEmpty: state[action.listName].displayEmpty,
        items: {
          ...state[action.listName].items,
          [action.item.id]: {
            selected: action.newValue,
            item: action.item
          }
        }
      };
      return { ...state };
    case "updateVisibleByFilterItems":
      state[action.listName] = {
        displayEmpty: action.displayEmpty,
        items: updateAllSelectedItems(
          state[action.listName].items,
          action.items
        )
      };
      return { ...state };
    case "setVisibleAllFilterItems":
      Object.keys(state[action.listName].items).map(
        key => (state[action.listName].items[key].selected = action.newValue)
      );
      state[action.listName] = {
        items: { ...state[action.listName].items },
        displayEmpty: action.newValue
      };
      return { ...state };
    case "clearFilter":
      state[action.listName] = {
        displayEmpty: true,
        items: updateAllSelectedItems(state[action.listName].items, true)
      };
      return { ...state };
    case "clearAllFilters":
      return {
        ...state,
        activeDivisions: {
          ...state.activeDivisions,
          displayEmpty: true,
          items: updateAllSelectedItems(state.activeDivisions.items, true)
        },
        activeGroups: {
          ...state.activeGroups,
          displayEmpty: true,
          items: updateAllSelectedItems(state.activeGroups.items, true)
        },
        activeManagers: {
          ...state.activeManagers,
          displayEmpty: true,
          items: updateAllSelectedItems(state.activeManagers.items, true)
        },
        activeStatuses: {
          ...state.activeStatuses,
          displayEmpty: true,
          items: updateAllSelectedItems(state.activeStatuses.items, true)
        }
      };
    case "updateVisibleEmptyFilterItem":
      state[action.listName] = {
        items: { ...state[action.listName].items },
        displayEmpty: action.newValue
      };
      return { ...state };
  }
};

export interface EmployeeDisplayList {
  loading: boolean;
  employees: api.Employee[];
  loadingEmployeeIds: boolean;
  allEmployeeIds?: string[];
  selectedEmployeeIds: string[];
  nextUrl: string | null;
  count: number;
  activeStatuses: FilterList<FilterListItem>;
  activeGroups: FilterList<FilterListItem>;
  activeManagers: FilterList<FilterListItem>;
  activeDivisions: FilterList<FilterListItem>;
}

type InitialStatusFilterValues = string[];

export type FilterListName =
  | "activeStatuses"
  | "activeDivisions"
  | "activeGroups"
  | "activeManagers";

interface SelectOneAction {
  type: "selectOne";
  index: number;
  newValue: boolean;
}

interface SelectAllAction {
  type: "selectAll";
}

interface SelectSomeAction {
  type: "selectSome";
  selectedEmployeeIds: string[];
}

interface SelectNoneAction {
  type: "selectNone";
}

interface SetLoadingEmployeeIdsAction {
  type: "setLoadingEmployeeIds";
  newValue: boolean;
}

interface SetAllEmployeeIdsAndSelectAllAction {
  type: "setAllEmployeeIdsAndSelectAll";
  allEmployeeIds: string[];
}

interface UpdateAllFilterItemAction {
  type: "setVisibleAllFilterItems";
  listName: FilterListName;
  newValue: boolean;
}

interface ClearFilterAction {
  type: "clearFilter";
  listName: FilterListName;
}

interface clearAllFiltersAction {
  type: "clearAllFilters";
}

interface UpdateEmployeeAction {
  type: "updateEmployee";
  updatedEmployee: api.Employee;
}
interface LoadDivisionsAction {
  type: "loadDivisions";
  newState: Omit<
    EmployeeDisplayList,
    | "loading"
    | "loadingEmployeeIds"
    | "nextUrl"
    | "employees"
    | "allEmployeeIds"
    | "selectedEmployeeIds"
    | "activeStatuses"
    | "activeGroups"
    | "activeManagers"
  >;
}

interface LoadGroupsAndLeadsAction {
  type: "loadGroupsAndLeads";
  newState: Omit<
    EmployeeDisplayList,
    | "loading"
    | "loadingEmployeeIds"
    | "nextUrl"
    | "employees"
    | "allEmployeeIds"
    | "selectedEmployeeIds"
    | "activeStatuses"
    | "activeDivisions"
  >;
}

interface RequestingAction {
  type: "requesting";
}

interface SetEmployeesAction {
  type: "setEmployees";
  newEmployees: api.Employee[];
  nextUrl: string | null;
  count: number;
}

interface AddEmployeesAction {
  type: "addEmployees";
  newEmployees: api.Employee[];
  nextUrl: string | null;
  count: number;
}

interface RemoveEmployeeAction {
  type: "removeEmployee";
  removedEmployee: api.Employee;
}

interface UpdateFilterItemAction {
  type: "updateVisibleByFilterItem";
  listName: FilterListName;
  newValue: boolean;
  item: FilterListItem;
}

interface UpdateFilterItemsAction {
  type: "updateVisibleByFilterItems";
  listName: FilterListName;
  items: string[];
  displayEmpty: boolean;
}

interface UpdateEmptyFilterItemAction {
  type: "updateVisibleEmptyFilterItem";
  listName: FilterListName;
  newValue: boolean;
}

export type EmployeeFilterActions =
  | SelectOneAction
  | SelectAllAction
  | SelectSomeAction
  | SelectNoneAction
  | SetLoadingEmployeeIdsAction
  | SetAllEmployeeIdsAndSelectAllAction
  | UpdateEmployeeAction
  | RequestingAction
  | LoadDivisionsAction
  | LoadGroupsAndLeadsAction
  | SetEmployeesAction
  | AddEmployeesAction
  | RemoveEmployeeAction
  | UpdateFilterItemAction
  | UpdateFilterItemsAction
  | UpdateAllFilterItemAction
  | ClearFilterAction
  | clearAllFiltersAction
  | UpdateEmptyFilterItemAction;
