import React, { FunctionComponent, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import { StatusModal } from "../status-calendar/StatusModal";

import { AttendanceEvent } from "./AttendanceEvent";

import * as api from "~/api";
import {
  APIResponse,
  AttendanceEventHistorySurvey,
  EmployeeEvent,
  EmployeeEventSurvey,
  getEmployeeAttendanceEventsHistory,
  getEmployeeAttendanceEventsHistorySurvey,
  retrieveEmployeeEventSurvey,
  updateEventSyncStatus
} from "~/api";
import YayIcon from "~/images/yay.svg";
import { MutableSessionContext } from "~/lib/context";
import { updateEvent } from "~/lib/employeeEvents";
import { useEffectOnce, useLinguiLanguage } from "~/lib/hooks";
import {
  getLabelColorDarkened,
  saveAttendanceStatusChange
} from "~/lib/status";
import Card from "~/mui-components/Card/Card";
import Typography from "~/mui-components/Typography/Typography";
import { TSButton, TSCollapse } from "~common";

const AttendanceHistory: FunctionComponent<AttendanceHistoryProps> = ({
  employee,
  surveyMode = false,
  header = undefined,
  showTitle = false,
  noTopPaddingHistory = false,
  getEmployeeAttendanceEventsAndPoints,
  setIsAttendanceHistoryData = undefined
}) => {
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [items, setItems] = useState<api.AttendanceEventHistory[]>([]);
  const [itemsByDate, setItemsByDate] = useState<
    api.AttendanceEventHistoryByDate[]
  >([]);
  const lastYearDate = DateTime.now()
    .minus({ years: 1 })
    .setLocale(language)
    .toFormat("LLL d, yyyy");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const modalDate = DateTime.local();

  React.useEffect(() => {
    setItemsByDate(getAttendanceEventHistoryByDate(items));
  }, [items]);

  const getAttendanceEventHistoryByDate = (
    events: api.AttendanceEventHistory[]
  ): api.AttendanceEventHistoryByDate[] => {
    const eventsByDate: api.AttendanceEventHistoryByDate[] = [];

    const getSimpleDate = (date: string): string =>
      new Date(date).toISOString().slice(0, 10);

    const isSameDay = (date1: string, date2: string): boolean =>
      getSimpleDate(date1) === getSimpleDate(date2);

    events.forEach(event => {
      const index = eventsByDate.findIndex(eventByDate =>
        isSameDay(eventByDate.date, event.event.started)
      );

      if (index >= 0) {
        eventsByDate[index].events.push(event);
      } else {
        eventsByDate.push({
          date: getSimpleDate(event.event.started),
          events: [event]
        });
      }
    });

    eventsByDate.map(eventByDate =>
      eventByDate.events.sort((e1, e2) =>
        new Date(e1.event.created) < new Date(e2.event.created) ? 1 : -1
      )
    );

    return eventsByDate;
  };

  const toggleItems = (events: api.AttendanceEventHistory[]): void => {
    if (events.length === 0) {
      return;
    }

    const isOpening = !events[0].isOpen;

    if (isOpening) {
      const promises = events.map(event => {
        const asyncFunc = async (): Promise<api.SurveyState | undefined> => {
          try {
            if (!event.isOpen && !event.survey) {
              const response = await retrieveEmployeeEventSurvey(
                employee.company_id,
                event.event.id
              );
              if (response?.ok && response?.data) {
                return response.data;
              }
            }
          } catch (error) {}
        };
        return asyncFunc();
      });

      Promise.all(promises).then(results => {
        let newItems = [...items];
        results.forEach((result, index) => {
          newItems = updateEvent(newItems, events[index], {
            ...events[index],
            isOpen: index === 0,
            survey: result || events[index].survey
          });
        });
        setItems(newItems);
      });
    } else {
      let newItems = [...items];
      events.forEach(event => {
        newItems = updateEvent(newItems, event, {
          ...event,
          isOpen: false
        });
      });
      setItems(newItems);
    }
  };

  const toggleItemLocally = (event: AttendanceEventHistorySurvey): void => {
    const eventSurvey = event.event.survey;

    if (eventSurvey) {
      setItems(
        updateEvent(items, event, {
          isOpen: !event.isOpen,
          survey: eventSurvey
        })
      );
    }
  };

  const onEventStatusSync = async (
    eventId: string,
    status: api.AlteredEventSyncStatus
  ): Promise<void> => {
    if (!session.company || !employee) {
      return;
    }
    try {
      const response = await updateEventSyncStatus(
        session.company.id,
        employee.id,
        eventId,
        status
      );

      if (response?.ok && response?.data) {
        const event = items.find(
          item => item.event.id === eventId
        ) as api.AttendanceEventHistory;

        setItems(
          updateEvent(items, event, {
            event: {
              ...event.event,
              sync_status: response.data.sync_status,
              sync_timestamp: response.data.sync_timestamp,
              last_sync_by: response.data.last_sync_by
            }
          })
        );
      }
    } catch {}
  };

  const updateEvents = async (refresh?: boolean): Promise<void> => {
    if (!employee) {
      return;
    }

    if (getEmployeeAttendanceEventsAndPoints && refresh) {
      getEmployeeAttendanceEventsAndPoints();
    }

    let response: APIResponse<EmployeeEventSurvey[] | EmployeeEvent[]>;

    if (surveyMode) {
      response = await getEmployeeAttendanceEventsHistorySurvey(
        employee.company_id,
        employee.id,
        { exclude: [api.LabelSet.PointsNotification] }
      );
    } else {
      response = await getEmployeeAttendanceEventsHistory(
        employee.company_id,
        employee.id
      );
    }

    if (response.ok && response.data) {
      if (setIsAttendanceHistoryData) {
        setIsAttendanceHistoryData(response.data.length > 0);
      }
      const attendanceEventHistory = response.data.map(
        (event: api.EmployeeEvent) => ({
          event,
          color: getLabelColorDarkened(event.label.name, session.labels),
          isOpen: items.find(item => item.event.id === event.id)?.isOpen,
          survey: items.find(item => item.event.id === event.id)?.survey
        })
      );

      setItems(attendanceEventHistory);
    }
  };

  const onAttendanceStatusSave = async (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const statusIsSaved = await saveAttendanceStatusChange(
      employee,
      startDate,
      endDate,
      status,
      session.labels,
      language,
      note,
      undefined
    );

    if (statusIsSaved && updateEvents) {
      updateEvents(true);
      handleExit();
    }
  };

  const handleNewStatus = (): void => {
    setShowStatusModal(true);
  };

  const handleExit = (): void => {
    setShowStatusModal(false);
  };

  useEffectOnce(() => {
    if (employee?.id) {
      updateEvents();
    }
  });

  const collapseAttendanceEvent = (
    surveyMode: boolean,
    items: any[],
    index: number
  ): JSX.Element => {
    const commonAttendanceEventProps = {
      employee,
      onEventStatusSync,
      updateEvents,
      withAnimation: true
    };

    if (surveyMode) {
      const [mainEvent, ...olderEventsSameDay] = items;

      type OlderEventsProps = {
        events: any[];
      };

      const OlderEvents = ({ events }: OlderEventsProps): JSX.Element => {
        if (events.length === 0) {
          return <></>;
        }
        return (
          <div className="divide-y-2">
            <div className="pt-3"></div>
            {events.map((item: any, mapIndex) => (
              <div
                className={clsx(
                  "py-3",
                  mapIndex === items.length - 1 && "pb-0"
                )}
                key={mapIndex}
              >
                <AttendanceEvent
                  attendanceEvent={item}
                  {...commonAttendanceEventProps}
                  automaticSync={false}
                  surveyMode={true}
                  showActions={false}
                  withAnimation
                />
              </div>
            ))}
          </div>
        );
      };

      return (
        <div
          className={clsx(
            "p-3 rounded-2xl w-full bg-white mb-3 sm:mb-2 border-1",
            {
              "p-4": !isMobile,
              "ml-2": !isMobile
            }
          )}
        >
          <TSCollapse
            key={index}
            color={items[0].color}
            isFirst={index === 0}
            onClick={() => toggleItemLocally(mainEvent)}
            isOpen={mainEvent.isOpen}
            surveyMode={true}
            showActions={mainEvent.event?.survey?.history.length >= 1}
          >
            <AttendanceEvent
              attendanceEvent={mainEvent}
              {...commonAttendanceEventProps}
              automaticSync={false}
              surveyMode={true}
              showActions={false}
              withAnimation
            >
              <OlderEvents events={olderEventsSameDay} />
            </AttendanceEvent>
          </TSCollapse>
        </div>
      );
    } else {
      const [mainEvent, ...olderEventsSameDay] = items;

      type OlderEventsProps = {
        events: any[];
      };

      const OlderEvents = ({ events }: OlderEventsProps): JSX.Element => {
        if (events.length === 0) {
          return <></>;
        }
        return (
          <div className="divide-y-2">
            <div className="pt-3"></div>
            {events.map((eventItem: any, eventIdx) => (
              <div
                className={clsx(
                  "py-3",
                  eventIdx === events.length - 1 && "pb-0"
                )}
                key={eventIdx}
              >
                {eventItem.event && (
                  <AttendanceEvent
                    attendanceEvent={eventItem}
                    {...commonAttendanceEventProps}
                    automaticSync={
                      session.features?.attendance_event_integration
                    }
                    showActions={eventItem.isOpen}
                    withAnimation
                  />
                )}
              </div>
            ))}
          </div>
        );
      };

      return (
        <TSCollapse
          key={mainEvent?.event?.id}
          color={mainEvent?.color}
          isOpen={mainEvent?.isOpen}
          isFirst={index === 0}
          onClick={() => toggleItems(items)}
        >
          <div
            className={clsx(
              "p-3 rounded-2xl w-full bg-white ml-2 mb-4 sm:mb-2 border-1",
              {
                "p-4": !isMobile,
                "ml-2": !isMobile
              }
            )}
          >
            <AttendanceEvent
              key={index}
              attendanceEvent={mainEvent}
              {...commonAttendanceEventProps}
              automaticSync={session.features?.attendance_event_integration}
              showActions={mainEvent.isOpen}
              withAnimation
            >
              <OlderEvents events={olderEventsSameDay} />
            </AttendanceEvent>
          </div>
        </TSCollapse>
      );
    }
  };

  const lastCollapseAttendanceEvent = (surveyMode: boolean): JSX.Element => {
    const label = (
      <Trans id="attendanceHistory.collapse.label" values={{ lastYearDate }}>
        Any occurrences before {lastYearDate} will not be shown here;
      </Trans>
    );
    if (surveyMode) {
      return (
        <div
          className={clsx(
            "p-3 rounded-2xl w-full bg-white mb-3 sm:mb-2 border-1",
            {
              "p-4": !isMobile,
              "ml-2": !isMobile
            }
          )}
        >
          <TSCollapse isLast surveyMode={true}>
            {label}
          </TSCollapse>
        </div>
      );
    } else {
      return (
        <TSCollapse isLast>
          <div
            className={clsx(
              "p-4 text-ts-gray-40 text-sm rounded-2xl w-full bg-white lg:mb-4 md:mb-4 border-1",
              "ml-2"
            )}
          >
            {label}
          </div>
        </TSCollapse>
      );
    }
  };

  return (
    <>
      {!surveyMode && (
        <div className={clsx("flex-row pb-2", !noTopPaddingHistory && "pt-8")}>
          <div className="grid grid-cols-2">
            <div className="text-ts-teal-20 text-lg font-semibold">
              Occurrence History
            </div>
            <div className="flex justify-end">
              <TSButton onClick={handleNewStatus} startIcon="icon-plus">
                New Occurrence
              </TSButton>
            </div>
          </div>

          {items.length === 0 && (
            <div className="sidebar-info-card card flex-row mt-4">
              <div className="p-4 flex flex-column w-full">
                <div className="flex flex-column flex-wrap items-center">
                  <img src={YayIcon} alt="yay" />
                  <div className="text-lg font-semibold leading-6 mt-4">
                    In the last 12 months, {employee.name} has had 0
                    occurrences.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(
          !surveyMode ? "mt-4 ml-4" : `m-3 mt-4 ${!isMobile && "pl-2"}`
        )}
      >
        {showTitle && (
          <Typography variant="h1" className="text-ts-teal-20 !font-bold">
            <Trans id="surveyAbsenceHistory.label.attendanceHistory">
              Attendance History
            </Trans>
          </Typography>
        )}
        {header && <Card>{header}</Card>}
      </div>
      {items.length > 0 && (
        <div
          className={clsx(
            !surveyMode ? "mt-4 ml-4" : `m-3 ${!isMobile && "pr-4"}`
          )}
        >
          {itemsByDate.map((item, index) =>
            collapseAttendanceEvent(surveyMode, item.events, index)
          )}

          {lastCollapseAttendanceEvent(surveyMode)}
        </div>
      )}
      {employee && (
        <StatusModal
          currentDay={modalDate}
          show={showStatusModal}
          handleExit={handleExit}
          employee={employee}
          timezone={employee.timezone}
          handleDeleteWarning={handleExit}
          onSave={onAttendanceStatusSave}
        />
      )}
    </>
  );
};

export default AttendanceHistory;

interface AttendanceHistoryProps {
  employee: api.Employee;
  surveyMode?: boolean;
  header?: JSX.Element;
  showTitle?: boolean;
  noTopPaddingHistory?: boolean;
  setIsAttendanceHistoryData?: (isAttendanceHistoryData: boolean) => void;
  getEmployeeAttendanceEventsAndPoints?: () => void;
}
