import React, { FunctionComponent } from "react";

import clsx from "clsx";
import Mustache from "mustache";

import * as api from "~/api";
import Typography from "~/mui-components/Typography/Typography";

export interface TextMessageProps {
  templateText: string;
  context: api.TemplateStringContext;
  theme?: "dark" | "green" | "light";
}

export const TextMessage: FunctionComponent<TextMessageProps> = ({
  theme = "light",
  templateText,
  context
}) => {
  const className = clsx(
    "w-fit",
    theme === "dark" && "bg-hs-black-20 text-white",
    theme === "green" && "bg-hs-green text-white"
    // light is now the default theme, dark and green should be removed soon
  );

  return (
    <div className="flex flex-col space-y-3">
      <Typography className={className} variant="textBubble">
        {Mustache.render(templateText, context)}
      </Typography>
    </div>
  );
};
