import * as React from "react";

import {
  default as MuiTypography,
  TypographyProps
} from "@mui/material/Typography";
import clsx from "clsx";

import ThemeLayout from "../Theme/ThemeLayout";

const Typography = (
  props: TypographyProps & {
    textBubbleProps?: { border?: boolean; teal?: boolean };
  }
): React.ReactElement<TypographyProps> => {
  const { children, textBubbleProps, className, ...componentProps } = props;

  return (
    <ThemeLayout>
      <MuiTypography
        className={clsx(
          textBubbleProps?.border && "use-border",
          textBubbleProps?.teal && "use-teal",
          className
        )}
        {...componentProps}
      >
        {children}
      </MuiTypography>
    </ThemeLayout>
  );
};

export default Typography;
