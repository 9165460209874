import React, { useContext } from "react";

import * as api from "~/api";
import "./SurveyAbsenceHistory.scss";
import { MutableSessionContext } from "~/lib/context";
import useChangeLanguage from "~/lib/hooks/useChangeLanguage";
import HeaderEmployeeExperience from "~/mui-components/HeaderEmployeeExperience/HeaderEmployeeExperience";
import { AttendanceHistory } from "~employees";

interface SurveyProps {
  employeeFromScreener?: api.Employee;
}

const SurveyAttendanceHistoryPage: React.FC<SurveyProps> = ({
  employeeFromScreener
}) => {
  const { session } = useContext(MutableSessionContext);
  const employee = employeeFromScreener ?? session?.employee;
  const changeLanguage = useChangeLanguage();

  return (
    <>
      <HeaderEmployeeExperience
        showBackButton={true}
        backButtonPosition="left"
        showLeftLabel={false}
        onBack={() => window.history.back()}
        languageOptions={session.company?.languages ?? []}
        onChangeLanguage={changeLanguage}
        languageSelectorId="language-selector-employee-experience"
      />
      <div className="ts max-w-3xl mx-auto">
        <div className="attendance-history-timeline">
          <AttendanceHistory
            employee={employee as api.Employee}
            surveyMode={true}
            showTitle={true}
          />
        </div>
      </div>
    </>
  );
};

export { SurveyAttendanceHistoryPage };
