import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as api from "~/api";
import "./SurveyAbsenceHistory.scss";
import { ConversationErrorPage } from "~/components";
import { MutableSessionContext } from "~/lib/context";
import useChangeLanguage from "~/lib/hooks/useChangeLanguage";
import BalanceCard from "~/mui-components/BalanceCard/BalanceCard";
import HeaderEmployeeExperience from "~/mui-components/HeaderEmployeeExperience/HeaderEmployeeExperience";

interface SurveyProps {
  employeeFromScreener?: api.Employee;
}

const SurveyBalancesPage: React.FC<SurveyProps> = () => {
  const [balances, setBalances] = useState<api.Balance[]>([]);
  const [isError, setIsError] = useState(false);
  const { session } = useContext(MutableSessionContext);
  const changeLanguage = useChangeLanguage();
  const { token: paramToken } = useParams<{ token: string }>();

  const fetchBalances = async (): Promise<void> => {
    try {
      const response = await api.retrieveEmployeeBalancesFromToken(paramToken);
      if (response.ok && response.data) {
        setBalances(response.data);
      } else {
        setIsError(true);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return <ConversationErrorPage />;
  }

  return (
    <>
      <HeaderEmployeeExperience
        showBackButton={true}
        backButtonPosition="left"
        showLeftLabel={false}
        onBack={() => window.history.back()}
        languageOptions={session.company?.languages ?? []}
        onChangeLanguage={changeLanguage}
        languageSelectorId="language-selector-employee-experience"
      />
      <div className="px-4 py-5 max-w-3xl mx-auto">
        <BalanceCard variant="employee" balances={balances} />
      </div>
    </>
  );
};

export { SurveyBalancesPage };
