import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router";

// Sources/Triggers query params enum
// These should be always in sync with the values in the backend
enum Source {
  SMS = "sms",
  EMAIL = "email",
  UNKNOWN = "unk"
}

enum Trigger {
  ABSENCE = "abs", // Absent (universal keywords)
  ADMIN = "admin", // Django admin
  FORM = "form", // TeamSense admin
  POINTS = "pts", // Points current value
  POINTS_NOTIFICATION = "ptsn", // Pounts automatic notifications
  PORTAL = "portal", // Employee portal
  REMINDER = "reminder", // Reminder notification
  UNKNOWN = "unk" // Unknown trigger
}

type Src = {
  source: Source | undefined;
  trigger: Trigger | undefined;
  rawQueryParams: string;
};

const SourceContext = createContext<Src | undefined>(undefined);

export const SourceWrapper: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const paramSrcKey = "src";
  const location = useLocation();
  const [src, setSrc] = useState<Src>({
    source: Source.UNKNOWN,
    trigger: Trigger.UNKNOWN,
    rawQueryParams: ""
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query && query.has(paramSrcKey)) {
      const rawSrc = query.get(paramSrcKey);
      if (rawSrc) {
        const [source, trigger] = rawSrc.split("_");
        const newSrc: Src = {
          source: Source.UNKNOWN,
          trigger: Trigger.UNKNOWN,
          rawQueryParams: query.toString()
        };
        if (Object.values(Source).includes(source as Source)) {
          newSrc.source = source as Source;
        }
        if (Object.values(Trigger).includes(trigger as Trigger)) {
          newSrc.trigger = trigger as Trigger;
        }
        setSrc(newSrc);
      }
    }
  }, [location]);

  return (
    <SourceContext.Provider value={src}>{children}</SourceContext.Provider>
  );
};

export const useSource = (): Src => {
  const context = useContext(SourceContext);
  if (context === undefined) {
    throw new Error("useSource must be used within a SourceWrapper");
  }
  return context;
};

export const isTriggerAbsent = (context: Src): boolean =>
  context.trigger === Trigger.ABSENCE;
