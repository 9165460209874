import React, { FunctionComponent } from "react";

import Mustache from "mustache";

import * as api from "~/api";
import { useConversationTranslation, useEffectOnce } from "~/lib/hooks";
import TextField from "~/mui-components/TextField/TextField";
import Typography from "~/mui-components/Typography/Typography";

export interface StringQuestionProps {
  questionMetadata: Pick<
    api.StringQuestion,
    "minLength" | "maxLength" | "hintExample"
  >;
  templateText: string;
  context?: api.TemplateStringContext;
  answer?: api.StringAnswer;
  disabled?: boolean;
  onChange?: (newAnswer: api.StringAnswer, valid: boolean) => any;
}

export const StringQuestion: FunctionComponent<StringQuestionProps> = ({
  questionMetadata,
  answer,
  templateText,
  context,
  disabled,
  onChange
}) => {
  const isValidLength = (answer: string): boolean => {
    const answerLength = answer?.length ?? 0;
    if (
      questionMetadata.minLength !== undefined &&
      answerLength < questionMetadata.minLength
    ) {
      return false;
    }
    if (
      questionMetadata.maxLength !== undefined &&
      answerLength > questionMetadata.maxLength
    ) {
      return false;
    }
    return true;
  };

  const placeholderText = useConversationTranslation(
    questionMetadata.hintExample
  );

  // Give calling components an opportunity to get initial state.
  useEffectOnce(() => {
    if (disabled) {
      return;
    }
    const textAnswer = answer?.value ?? "";
    onChange?.(
      { kind: "string", value: textAnswer },
      isValidLength(textAnswer)
    );
  });

  return (
    <div className="flex flex-col space-y-3">
      <Typography className="w-fit" variant="textBubble">
        {Mustache.render(templateText, context)}
      </Typography>
      {disabled && (
        <Typography
          className="w-fit"
          variant="textBubble"
          textBubbleProps={{ border: true, teal: true }}
        >
          {answer?.value || "No answer provided"}
        </Typography>
      )}
      {!disabled && (
        <TextField
          placeholder={placeholderText ? placeholderText : undefined}
          value={answer?.value ?? ""}
          multiline
          onChange={value =>
            onChange?.(
              { kind: "string", value: value.target?.value || "" },
              isValidLength(value.target?.value || "")
            )
          }
        />
      )}
    </div>
  );
};
