import React, { FunctionComponent, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import * as Sentry from "@sentry/react";

import * as api from "~/api";
import {
  ConfirmPhoneNumber,
  PhoneNumberUpdated,
  PreviousPhoneNumber
} from "~/components/IdentifyEmployee";
import { generateLanguageList } from "~/components/LanguageDropdown/LanguageDropdown";
import { EmployeeID, Success } from "~/components/RegisterEmployee";
import HeaderEmployeeExperience from "~/mui-components/HeaderEmployeeExperience/HeaderEmployeeExperience";
import { GenericErrorText } from "~common";

interface RouteParamTypes {
  screen: string;
}

enum Screen {
  EmployeeID = 1,
  PhoneNumber = 2,
  PreviousPhoneNumber = 3,
  PhoneNumberUpdated = 4,
  Success = 5
}

export const IdentifyEmployee: FunctionComponent = () => {
  const SentryRoute = Sentry.withSentryRouting(Route);

  const [currentEmployeeId, setCurrentEmployeeId] = useState<string>();
  const [confirmingId, setConfirmingId] = useState(false);
  const [errorConfirmingId, setErrorConfirmingId] = useState<Error>();
  const [identifyResponse, setIdentifyResponse] =
    useState<api.IdentifyEmployeeResponse>();
  const [requestSurveyToken, setRequestSurveyToken] = useState<string>();
  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [confirmingPreviousPhone, setConfirmingPreviousPhone] = useState(false);
  const [errorConfirmingPreviousPhone, setErrorConfirmingPreviousPhone] =
    useState(false);
  const [requestingSurvey, setRequestingSurvey] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const language = query.get("lang") ?? "en-US";
  const token = query.get("st") ?? undefined;
  const history = useHistory();
  const match = useRouteMatch();
  const matchScreen = useRouteMatch<RouteParamTypes>(`${match.path}/:screen`);
  const { screen } = matchScreen?.params || {};
  const screenValue = parseInt(screen ?? `${Screen.EmployeeID}`);
  const showBackButton =
    screenValue !== Screen.EmployeeID && screenValue !== Screen.Success;

  const toastGenericError = (options?: ToastOptions): void => {
    toast.error(<GenericErrorText />, options);
  };

  const navigateToScreen = (screen: Screen): void =>
    history.push({ pathname: `${match.path}/${screen}`, search });

  const onChangeLanguage = (lang: string): void => {
    // TODO: Replace by useChangeLanguage hook
    query.set("lang", lang);
    window.location.assign(`${match.path}/?${query}`);
  };

  const onConfirmEmployeeId = async (employeeId: string): Promise<void> => {
    setCurrentEmployeeId(employeeId);
    setErrorConfirmingId(undefined);
    setConfirmingId(true);

    let response: api.APIResponse<api.IdentifyEmployeeResponse> | undefined;
    if (token) {
      try {
        response = await api.identifyEmployee(token, employeeId.trim());
      } catch {}
    }

    setConfirmingId(false);

    if (!response?.ok) {
      const error = new Error();

      switch (response?.status) {
        case 409:
          error.name = "conflict";
          break;

        case 403:
        case 401:
          error.name = "tokenError";
          break;

        default:
          error.name = "unknown";
          break;
      }
      setErrorConfirmingId(error);
      return;
    }

    setIdentifyResponse(response.data);
    navigateToScreen(Screen.PhoneNumber);
  };

  const onUpdatePhone = async (): Promise<void> => {
    setUpdatingPhone(true);

    let response: api.APIResponse<api.SurveyTokenResponse> | undefined;
    if (identifyResponse) {
      try {
        response = await api.updateIdentifiedEmployeePhone(
          identifyResponse.token
        );
      } catch {}
    }

    setUpdatingPhone(false);

    if (!response?.ok) {
      toastGenericError();
      return;
    }

    setRequestSurveyToken(response.data.request_survey_token);
    navigateToScreen(Screen.PhoneNumberUpdated);
  };

  const onRequestSurvey = async (token?: string): Promise<void> => {
    setRequestingSurvey(true);

    const tokenToUse = token ?? requestSurveyToken;

    let response: api.APIResponse<api.SurveyLink> | undefined;
    if (tokenToUse) {
      try {
        response = await api.requestSurveyFromSMSIdentification(tokenToUse);
      } catch {}
    }

    setRequestingSurvey(false);

    if (!response?.ok || !response.data.url) {
      toastGenericError();
      return;
    }

    // NOTE: if we use history.push() here, react-router-dom won't load the token properly
    window.location.assign(response.data.url);
  };

  const onConfirmPreviousPhone = async (
    previousPhone: string
  ): Promise<void> => {
    setErrorConfirmingPreviousPhone(false);
    setConfirmingPreviousPhone(true);
    let response: api.APIResponse<api.SurveyTokenResponse> | undefined;
    if (identifyResponse) {
      try {
        response = await api.confirmIdentifiedEmployeePhone(
          identifyResponse.token,
          previousPhone
        );
      } catch {}
    }

    setConfirmingPreviousPhone(false);

    if (!response?.ok) {
      setErrorConfirmingPreviousPhone(true);
      return;
    }

    setRequestSurveyToken(response.data.request_survey_token);
    // Immediately request a survey with this token (not yet reflected in state)
    onRequestSurvey(response.data.request_survey_token);
  };

  return (
    <div className="wk-min-h-screen h-full w-screen bg-white flex flex-col">
      <HeaderEmployeeExperience
        companyName={identifyResponse?.company_name}
        showBackButton={showBackButton}
        onBack={() => history.goBack()}
        selectedLanguage={language}
        onChangeLanguage={onChangeLanguage}
        languageOptions={generateLanguageList(undefined, !showBackButton).map(
          ({ id, text }) => ({
            value: id,
            label: text
          })
        )}
      />
      <Switch>
        {identifyResponse && (
          <SentryRoute
            path={`${match.path}/${Screen.PhoneNumber}`}
            render={() => (
              <ConfirmPhoneNumber
                identifyResponse={identifyResponse}
                requesting={updatingPhone}
                onUpdate={onUpdatePhone}
                onDontUpdate={() =>
                  navigateToScreen(Screen.PreviousPhoneNumber)
                }
              />
            )}
            exact
          />
        )}
        {identifyResponse && (
          <SentryRoute
            path={`${match.path}/${Screen.PhoneNumberUpdated}`}
            render={() => (
              <PhoneNumberUpdated
                identifyResponse={identifyResponse}
                requesting={requestingSurvey}
                /* Call onRequestSurvey with no args so we use the requestSurveyToken from state */
                onRequestSurvey={() => onRequestSurvey()}
                onDone={() => navigateToScreen(Screen.Success)}
              />
            )}
            exact
          />
        )}
        {identifyResponse && (
          <SentryRoute
            path={`${match.path}/${Screen.Success}`}
            render={() => (
              <Success
                friendlyName={identifyResponse.friendly_name}
                goodToGoTitle
                hideDetail
              />
            )}
            exact
          />
        )}
        {currentEmployeeId && (
          <SentryRoute
            path={`${match.path}/${Screen.PreviousPhoneNumber}`}
            render={() => (
              <PreviousPhoneNumber
                requesting={confirmingPreviousPhone}
                error={errorConfirmingPreviousPhone}
                onConfirm={onConfirmPreviousPhone}
              />
            )}
            exact
          />
        )}
        <SentryRoute
          path={match.path}
          render={() => (
            <EmployeeID
              hideHeader
              language={language}
              requesting={confirmingId}
              error={errorConfirmingId}
              onChangeLanguage={onChangeLanguage}
              onConfirm={onConfirmEmployeeId}
            />
          )}
        />
      </Switch>
    </div>
  );
};
