import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";
import { hexToRgba } from "../utils";

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    standard: true;
  }
}

const InputTheme: MuiElementTheme<"MuiTextField"> = {
  defaultProps: {},
  styleOverrides: {
    root: {
      "&.hovered": {
        "& .MuiFormLabel-root": {
          color: colors.gray[20]
        },
        "& input::placeholder": {
          color: colors.gray[30]
        }
      },
      "&.no-label": {
        "& .MuiInputBase-input": {
          paddingTop: "1.1rem",
          paddingBottom: "0.9rem"
        },
        "&.multiline": {
          "& .MuiInputBase-root": {
            paddingTop: "0rem",
            paddingBottom: "0rem"
          }
        }
      },
      "& .MuiInputBase-root.Mui-disabled": {
        WebkitTextFillColor: colors.gray[50]
      },
      "& .MuiFormLabel-root.Mui-disabled": {
        WebkitTextFillColor: colors.gray[50]
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: colors.gray[50]
      },
      "& .MuiFormLabel-root": {
        fontSize: "1rem",
        weight: "500",
        lineHeight: "1.8rem",
        color: colors.gray[30],
        "&.Mui-focused": {
          color: colors.teal[40]
        },
        "&.Mui-error": {
          color: colors.red[20]
        }
      },
      "& .MuiFormLabel-asterisk": {
        fontSize: "1.875rem",
        fontWeight: "700",
        float: "inline-end",
        color: colors.red[20]
      },
      "& .MuiInputBase-input": {
        fontSize: "1rem",
        color: colors.gray[10],
        weight: "500",
        lineHeight: "1.5rem"
      },
      "& .MuiFormHelperText-root": {
        fontSize: "0.75rem",
        color: colors.gray[30],
        lineHeight: "1rem",
        weight: "500",
        "&.Mui-error": {
          color: colors.red[20]
        }
      },
      "& input::placeholder": {
        fontSize: "1rem",
        color: colors.gray[40],
        opacity: 1,
        weight: "500",
        lineHeight: "1.5rem"
      },
      "& .MuiFilledInput-root": {
        "& input, & textarea": {
          boxShadow: "none"
        },
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px",
        borderColor: colors.gray[40],
        "&:hover": {
          backgroundColor: colors.gray[90],
          borderColor: colors.gray[30]
        },
        "&:blur": {
          backgroundColor: "transparent"
        },
        "&.Mui-focused": {
          backgroundColor: "transparent",
          borderColor: "transparent",
          boxShadow: `inset 0 0 0 3px ${hexToRgba(colors.teal[40], 0.8)}`,
          "&.Mui-error": {
            boxShadow: `inset 0 0 0 3px ${colors.red[20]}`
          },
          transition:
            "box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out"
        },
        "&.Mui-error": {
          borderColor: colors.red[20]
        },
        "&.Mui-disabled": {
          backgroundColor: colors.gray[80],
          borderColor: colors.gray[70]
        }
      }
    }
  }
};

export default InputTheme;
