import React, { useState } from "react";

import Tab from "@mui/material/Tab";
import { default as MuiTabs, TabsProps } from "@mui/material/Tabs";

import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

import { states } from "./TabsTheme";

type TabKey = number | string;

interface CustomTabsProps extends TabsProps {
  tabs: {
    key?: TabKey;
    label: string | React.ReactElement;
    disabled?: boolean;
    icon?: React.ReactElement;
  }[];
  selectedTab: TabKey;
  setSelectedTab: (value: TabKey) => void;
}

const Tabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  ...componentProps
}: CustomTabsProps): React.ReactElement<CustomTabsProps> => {
  const [tabHovered, setTabHovered] = useState<number | null>(null);
  const [tabPressed, setTabPressed] = useState<number | null>(null);
  const [tabFocused, setTabFocused] = useState<number | null>(null);

  const getBarColor = (): string => {
    if (tabPressed === selectedTab) {
      return states.pressed.active.barColor;
    } else if (tabHovered === selectedTab) {
      return states.hover.active.barColor;
    } else if (tabFocused === selectedTab) {
      return states.focused.active.barColor;
    }
    return "";
  };

  return (
    <ThemeLayout>
      <MuiTabs
        value={selectedTab}
        onChange={(_, new_value: TabKey) => setSelectedTab(new_value)}
        variant="fullWidth"
        sx={{ ".MuiTabs-indicator": { backgroundColor: getBarColor() } }}
        {...componentProps}
      >
        {(tabs || []).map((tab, index) => (
          <Tab
            key={index}
            label={<Typography variant="h4">{tab.label}</Typography>}
            disabled={tab.disabled}
            icon={tab.icon}
            value={tab.key || index}
            onMouseEnter={() => setTabHovered(index)}
            onMouseLeave={() => setTabHovered(null)}
            onMouseDown={() => setTabPressed(index)}
            onMouseUp={() => setTabPressed(null)}
            onFocus={() => setTabFocused(index)}
            onBlur={() => setTabFocused(null)}
          />
        ))}
      </MuiTabs>
    </ThemeLayout>
  );
};

export default Tabs;
