import React, { FunctionComponent, useContext } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import { SplitFactory } from "@splitsoftware/splitio-react";

import { SourceWrapper } from "./ts-components/common/auth/SourceWrapper";
import { Error404 } from "./ts-pages/Error404";
import { AzureLogin } from "./views/AzureLogin";

import { Pendo } from "~/components";
import { MutableSessionContext } from "~/lib/context";
import {
  CompanyAdminRoute,
  GuestRoute,
  TransitionRoute,
  UserRoute
} from "~/lib/routing";
import { Demo } from "~/ts-demo/Demo";
import { EmployeeMessage } from "~/ts-pages";
import {
  Dashboard,
  DirectReports,
  EditEmployeeSchedule,
  EmployeePortal,
  IdentifyEmployee,
  Login,
  LoginForgot,
  LoginReset,
  Logout,
  RegisterEmployeeInfo,
  RequestCheckin,
  ResetSent,
  ScreenerView,
  PreSurvey,
  SurveyAttendanceHistoryPage,
  SurveyBalancesPage,
  VisitorPrintInstructions
} from "~/views";
import {
  TSToastProvider,
  InternationalizationWrapper,
  AuthWrapper,
  AzureAuthRedirect,
  AzureLogout
} from "~common";

import "react-toastify/dist/ReactToastify.css";
import "./app.scss";

const SentryRoute = Sentry.withSentryRouting(Route);

const SPLIT_CORE_CONFIG = {
  authorizationKey: process.env.REACT_APP_SPLIT_API_KEY || "localhost",
  trafficType: "company"
};

const CompanySplitFactory: FunctionComponent<{ children: React.ReactNode }> = ({
  children
}) => (
  <SplitFactory
    config={{
      core: {
        ...SPLIT_CORE_CONFIG,
        key: useContext(MutableSessionContext).session.company?.id ?? "none"
      },
      storage: {
        type: "LOCALSTORAGE"
      }
    }}
  >
    <>{children}</>
  </SplitFactory>
);

const App: FunctionComponent = () => {
  interface ParamTypes {
    conversationToken?: string;
    employeeSessionToken?: string;
  }

  const { conversationToken, employeeSessionToken } = useParams<ParamTypes>();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("t");

  LicenseInfo.setLicenseKey(process.env.MATERIAL_UI_LICENSE_KEY || "");

  return (
    <AuthWrapper
      conversationToken={conversationToken}
      employeeSessionToken={token ?? employeeSessionToken}
    >
      <InternationalizationWrapper>
        <SourceWrapper>
          <TSToastProvider
            position="top-center"
            autoClose={5000}
            pauseOnHover
            pauseOnFocusLoss
            closeOnClick
          >
            <CompanySplitFactory>
              <BrowserRouter>
                {/* When adding new routes, be sure to exclude in /robots.txt */}
                <Switch>
                  <SentryRoute
                    path={["/s/:token", "/c/:token"]}
                    component={PreSurvey}
                    exact
                  />
                  <SentryRoute
                    path="/s/:token/attendance-history"
                    component={SurveyAttendanceHistoryPage}
                    exact
                  />
                  <SentryRoute
                    path="/s/:token/balances"
                    component={SurveyBalancesPage}
                    exact
                  />
                  <SentryRoute
                    path={[
                      "/menu/:token",
                      "/portal/:token",
                      "/attendance/:token"
                    ]}
                    component={EmployeePortal}
                    exact
                  />
                  <SentryRoute
                    path={"/m/:token"}
                    component={EmployeeMessage}
                    exact
                  />
                  <SentryRoute path="/go" component={RequestCheckin} exact />
                  <SentryRoute path="/identify" component={IdentifyEmployee} />
                  <SentryRoute
                    path="/register"
                    component={RegisterEmployeeInfo}
                  />
                  <GuestRoute
                    path="/sign-in-azure"
                    component={AzureLogin}
                    exact
                  />
                  <SentryRoute
                    path="/sign-out-azure"
                    component={AzureLogout}
                    exact
                  />
                  <TransitionRoute path="/sign-in" exact>
                    <GuestRoute component={Login} />
                  </TransitionRoute>

                  <TransitionRoute path="/sign-in/password" exact>
                    <GuestRoute component={Login} />
                  </TransitionRoute>

                  <TransitionRoute path="/sign-in/forgot" exact>
                    <GuestRoute component={LoginForgot} />
                  </TransitionRoute>

                  <TransitionRoute path="/sign-in/forgot/sent" exact>
                    <GuestRoute component={ResetSent} />
                  </TransitionRoute>

                  <TransitionRoute path="/sign-in/reset/:token" exact>
                    <GuestRoute component={LoginReset} />
                  </TransitionRoute>

                  <TransitionRoute path="/logout" exact>
                    <SentryRoute component={Logout} />
                  </TransitionRoute>
                  <CompanyAdminRoute path="/dashboard" component={Dashboard} />
                  <SentryRoute
                    path="/manager/directs/:token"
                    component={DirectReports}
                    exact
                  />
                  <UserRoute
                    path="/manager/directs"
                    component={DirectReports}
                    exact
                  />
                  <SentryRoute
                    path="/manager/employee/:employeeId/schedule"
                    component={EditEmployeeSchedule}
                    exact
                  />
                  <SentryRoute
                    path="/screening/:token"
                    component={ScreenerView}
                    exact
                  />
                  <SentryRoute path="/" exact>
                    <Redirect to="/dashboard" />
                  </SentryRoute>
                  <SentryRoute
                    path="/auth/redirect"
                    exact
                    component={AzureAuthRedirect}
                  />
                  <SentryRoute path="/login" exact>
                    <Redirect to="/sign-in" />
                  </SentryRoute>
                  <SentryRoute
                    path="/visitor-instructions"
                    component={VisitorPrintInstructions}
                    exact
                  />
                  <SentryRoute path="/demo" component={Demo} exact />
                  <SentryRoute component={Error404} />
                </Switch>
              </BrowserRouter>
            </CompanySplitFactory>
            <Pendo />
          </TSToastProvider>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            closeButton={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div id="ts-modal" className="ts"></div>
        </SourceWrapper>
      </InternationalizationWrapper>
    </AuthWrapper>
  );
};

export default App;
