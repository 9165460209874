import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Toolbar from "@mui/material/Toolbar";
import { ArrowCounterClockwise, CaretLeft } from "@phosphor-icons/react";
import clsx from "clsx";

import Button from "../Button/Button";
import Select from "../Select/Select";
import LogoIcon from "../SVGs/LogoIcon";
import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

import { useLinguiLanguage } from "~/lib/hooks";
import { displayLanguage } from "~/lib/languages";

type LanguageOption = {
  label: string;
  value: string; // language ISO code
};

type HeaderEmployeeExperienceProps = {
  companyName?: string;
  showLeftLabel?: boolean;
  showUndoButton?: boolean;
  onUndo?: () => void;
  showBackButton?: boolean;
  backButtonPosition?: "left" | "right";
  onBack?: () => void;
  locale?: string;
  selectedLanguage?: string;
  languageOptions?: LanguageOption[] | string[];
  onChangeLanguage?: (language: string) => void;
  subHeader?: React.ReactNode;
  displayLanguageName?: boolean;
  languageSelectorId?: string;
};

const HeaderEmployeeExperience = ({
  companyName,
  showLeftLabel = true,
  showUndoButton = false,
  onUndo,
  showBackButton = false,
  backButtonPosition = "right",
  onBack,
  locale = "en-US",
  // eslint-disable-next-line react-hooks/rules-of-hooks
  selectedLanguage = useLinguiLanguage(),
  languageOptions = [],
  onChangeLanguage,
  subHeader,
  displayLanguageName = true,
  languageSelectorId = "language-selector-employee-experience"
}: HeaderEmployeeExperienceProps): React.ReactElement => {
  const getDate = (locale?: string): string => {
    return new Intl.DateTimeFormat(locale || "en-US", {
      weekday: "short",
      month: "short",
      day: "numeric"
    }).format(new Date());
  };

  const BackButton = (): React.ReactElement => (
    <Button
      variant="text"
      size="small"
      startIcon={<CaretLeft />}
      onClick={onBack}
    >
      Back
    </Button>
  );

  const languageOptionsWithNames = languageOptions.map(option =>
    typeof option === "string"
      ? {
          label: displayLanguage(option),
          value: option
        }
      : {
          label: displayLanguage(option.value),
          value: option.value
        }
  );

  return (
    <ThemeLayout>
      <Box className="flex-grow">
        <AppBar
          position="static"
          variant="outlined"
          className="!rounded-none !border-x-0 !border-b !border-t-0 !border-ts-gray-60 !bg-white"
        >
          <Toolbar variant="dense" className="flex justify-between">
            <Box className="flex items-center gap-2">
              {showLeftLabel && (
                <>
                  <LogoIcon />
                  <Typography
                    className={clsx(
                      "!text-xs !font-bold !tracking-wide",
                      companyName ? "text-ts-teal-30" : "text-ts-gray-30"
                    )}
                  >
                    {companyName || getDate(locale)}
                  </Typography>
                </>
              )}
              {showBackButton && backButtonPosition === "left" && (
                <BackButton />
              )}
            </Box>
            <Box className="flex items-center gap-2">
              {showBackButton && backButtonPosition === "right" && (
                <BackButton />
              )}
              {showUndoButton && (
                <ButtonBase onClick={() => onUndo?.()} className="!px-[6px]">
                  <ArrowCounterClockwise
                    color={colors.teal[40]}
                    size={20}
                  ></ArrowCounterClockwise>
                </ButtonBase>
              )}
              {languageOptions && languageOptions.length > 0 && (
                <Select
                  id={languageSelectorId}
                  value={selectedLanguage}
                  onChange={newLanguage =>
                    onChangeLanguage?.(newLanguage as string)
                  }
                  items={
                    displayLanguageName
                      ? languageOptionsWithNames
                      : languageOptions
                  }
                  size="small"
                />
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {subHeader && (
          <Box className="flex justify-center bg-ts-teal-80 py-2">
            <Typography
              className="!text-sm !font-semibold"
              sx={{ color: colors.teal[30] }}
            >
              {subHeader}
            </Typography>
          </Box>
        )}
      </Box>
    </ThemeLayout>
  );
};

export default HeaderEmployeeExperience;
