import { useLocation, useRouteMatch } from "react-router-dom";

const useChangeLanguage = (): ((lang: string) => void) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const match = useRouteMatch();

  return (lang: string): void => {
    query.set("lang", lang);
    window.location.assign(`${match.url}?${query}`);
  };
};

export default useChangeLanguage;
