import * as React from "react";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApiPro,
  GridCellProps,
  GridColDef,
  GridColumnMenuProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridHeaderFilterCellProps,
  GridInitialState,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
  GridToolbarProps,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import {
  MemorizedCellRow,
  MemorizedColumnMenu,
  MemorizedHeaderFilterCell,
  MemorizedNoRows,
  MemorizedToolbar
} from "./CustomGridSlots";
import DataGrid from "./DataGrid";
import gridHeaders, { EmployeeTableHeaders } from "./GridHeaders";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/mutableSession";
import {
  EmployeeDisplayList,
  EmployeeFilterActions
} from "~/lib/employeesList";
import { FilterListItem, SessionKey } from "~/lib/filterList";
import { useLinguiLanguage } from "~/lib/hooks";

export const STORAGE_KEY = "dataGridState";

type EmployeesTableProps = {
  deactivatedMode: boolean;
  displayList: EmployeeDisplayList;
  dispatch: (value: EmployeeFilterActions) => void;
  searchText?: string;
  groups: api.Group[];
  managers: api.IdentificationEmployee[];
  statuses: FilterListItem[];
  divisions: FilterListItem[];
  // onRequestAllEmployeeIds?: () => any;
  onSelectEmployees?: (selectedEmployeeIds: api.Employee["id"][]) => void;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => void;
  onReactivateClick?: (employee: api.Employee) => void;
  onPageChange?: (page: number, pageSize: number) => void;
  onSortChange?: (order: string) => void;
  isLoading?: boolean;
  initPage?: number;
  initPageSize?: number;
  storageType?: "LOCAL" | "SESSION";
};

const getStoragedState = (storage: Storage): GridInitialStatePro => {
  const gridData = storage?.getItem(STORAGE_KEY);
  if (gridData) {
    try {
      return JSON.parse(gridData);
    } catch (e) {
      console.error(e);
    }
  }
  return {};
};

const sortModelToString = (sortModel: GridSortModel): string => {
  return sortModel
    .map((prop: GridSortItem) => {
      return `${prop.sort === "asc" ? "" : "-"}${prop.field}`;
    })
    .join(",")
    .replaceAll("team_lead", "team_lead__name")
    .replaceAll("division", "division__name");
};

const saveSnapshotWithCompatibility = (
  state: GridInitialStatePro,
  storage: Storage
): void => {
  const search: string | undefined =
    state.filter?.filterModel?.quickFilterValues?.[0];
  if (search) {
    storage.setItem(SessionKey.employeesSearchFilter, search);
  }
  const sorting = state.sorting?.sortModel;
  if (sorting) {
    storage.setItem(SessionKey.employeesSorting, sortModelToString(sorting));
  }
  const filters = [
    {
      filterKey: "division",
      storageKey: SessionKey.employeesDivisionFilter
    },
    {
      filterKey: "team_lead",
      storageKey: SessionKey.employeesManagerFilter
    },
    {
      filterKey: "status",
      storageKey: SessionKey.employeesStatusFilter
    },
    {
      filterKey: "groups",
      storageKey: SessionKey.employeesGroupFilter
    }
  ];
  filters.forEach(({ filterKey, storageKey }) => {
    const filter: string[] = state.filter?.filterModel?.items?.find(
      item => item.field === filterKey
    )?.value;
    if (filter) {
      storage.setItem(
        storageKey,
        JSON.stringify({
          ids: filter.filter(item => item !== "none"),
          none: filter.includes("none")
        })
      );
    }
  });
};

const EmployeesTable = ({
  displayList,
  deactivatedMode,
  searchText,
  managers,
  groups,
  statuses,
  divisions,
  isLoading,
  onSelectEmployees,
  onPageChange,
  onSortChange,
  dispatch,
  onEditStatusClick,
  onReactivateClick,
  initPage = 0,
  initPageSize = 100,
  storageType = "SESSION"
}: EmployeesTableProps): JSX.Element => {
  const apiRef = useGridApiRef();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const rows = useMemo(() => displayList.employees, [displayList.employees]);
  const rowCount = useMemo(() => displayList.count || 0, [displayList.count]);

  const [paginationModel, setPaginationModel] = useState({
    page: initPage,
    pageSize: initPageSize
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [isShowHeaderFilters, setIsShowHeaderFilters] = useState(false);
  const [firstHeaderRender, setFirstHeaderRender] = useState(true);

  const storage = { LOCAL: localStorage, SESSION: sessionStorage }[storageType];

  const saveSnapshot = useCallback(
    (newSearchText: string | undefined = undefined) => {
      if (apiRef?.current?.exportState && storage) {
        const currentState: GridInitialStatePro = apiRef.current.exportState();
        if (newSearchText !== undefined && currentState.filter?.filterModel) {
          currentState.filter.filterModel.quickFilterValues = [newSearchText];
        }
        storage.setItem(STORAGE_KEY, JSON.stringify(currentState));
        saveSnapshotWithCompatibility(currentState, storage);
      }
    },
    [apiRef, storage]
  );

  useEffect(() => {
    saveSnapshot(searchText);
  }, [saveSnapshot, searchText]);

  useLayoutEffect(() => {
    const savedState = getStoragedState(storage);
    setInitialState(savedState || {});
    if (savedState?.filter?.filterModel) {
      setFilterModel(savedState.filter.filterModel);
    }
    if (savedState?.sorting?.sortModel) {
      setSortModel(savedState.sorting.sortModel);
    }
    if (savedState?.columns?.columnVisibilityModel) {
      setColumnVisibilityModel(savedState.columns.columnVisibilityModel);
    }
    // TODO: (Beyond parity) Save page isn't currently supoprted by prev table
    // if (savedState?.pagination) {
    //   setPaginationModel(savedState.pagination);
    // }
    return () => saveSnapshot();
  }, [saveSnapshot, storage]);

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        gridHeaders(EmployeeTableHeaders.FirstName),
        gridHeaders(EmployeeTableHeaders.LastName),
        gridHeaders(EmployeeTableHeaders.ExternalId),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Status, {
              session,
              language,
              onEditStatusClick,
              filter: {
                dispatch,
                options: statuses,
                dispatchListName: "activeStatuses",
                initialState: initialState?.filter?.filterModel,
                noOptionText: "(No status)"
              }
            })
        ],
        gridHeaders(EmployeeTableHeaders.Division, {
          filter: {
            dispatch,
            options: divisions,
            dispatchListName: "activeDivisions",
            initialState: initialState?.filter?.filterModel,
            noOptionText: "(No division)"
          }
        }),
        gridHeaders(EmployeeTableHeaders.Manager, {
          filter: {
            dispatch,
            options: managers,
            dispatchListName: "activeManagers",
            initialState: initialState?.filter?.filterModel,
            noOptionText: "(No managers)"
          }
        }),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Groups, {
              filter: {
                dispatch,
                options: groups,
                dispatchListName: "activeGroups",
                initialState: initialState?.filter?.filterModel,
                noOptionText: "(No groups)"
              }
            })
        ],
        ...[
          deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Reactivate, {
              onReactivateClick
            })
        ],
        ...[!deactivatedMode && gridHeaders(EmployeeTableHeaders.Arrow)]
      ].filter(Boolean) as GridColDef[],
    [
      session,
      language,
      onEditStatusClick,
      dispatch,
      statuses,
      initialState?.filter?.filterModel,
      divisions,
      managers,
      groups,
      deactivatedMode,
      onReactivateClick
    ]
  );

  const onPaginationChange = useCallback(
    (model: GridPaginationModel): void => {
      const newPaginationModel: GridPaginationModel = {
        ...paginationModel,
        ...model
      };
      setPaginationModel(newPaginationModel);
      onPageChange?.(newPaginationModel.page, newPaginationModel.pageSize);

      saveSnapshot();
      requestAnimationFrame(() => {
        // requestAnimationFrame is used to ensure that
        // the scroll is done after the page change
        setTimeout(() => {
          // setTimeout is used to ensure that the scroll
          // is the last action done after the page change
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
      });
    },
    [onPageChange, paginationModel, saveSnapshot]
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel): void => {
      const newSortModel = { sortModel: [...sortModel] };
      const sortingString = sortModelToString(newSortModel.sortModel);
      setSortModel(sortModel);
      onSortChange?.(sortingString);
      saveSnapshot();
    },
    [onSortChange, saveSnapshot]
  );

  const onFilterModelChange = (newFilterModel: GridFilterModel): void => {
    const newItems: GridFilterModel["items"] = [...(filterModel?.items || [])];
    newFilterModel.items?.forEach(item => {
      const index = newItems.findIndex(newItem => newItem.field === item.field);
      if (index === -1) {
        newItems.push(item);
      } else {
        newItems[index] = item;
      }
    });
    setFilterModel({ ...filterModel, items: newItems });
    saveSnapshot();
  };

  const onColumnVisibilityModelChange = useCallback(
    (newColumnVisibilityModel: GridColumnVisibilityModel): void => {
      setColumnVisibilityModel(newColumnVisibilityModel);
      saveSnapshot();
    },
    [saveSnapshot]
  );

  const onRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel): void => {
      onSelectEmployees?.(newRowSelectionModel as string[]);
    },
    [onSelectEmployees]
  );

  const toggleFilters = useCallback((): void => {
    const isShow = !isShowHeaderFilters;
    if (!isShow) {
      setFilterModel(undefined);
    }
    setIsShowHeaderFilters(isShow);
    setFirstHeaderRender(false);
  }, [isShowHeaderFilters]);

  const clearFilters = useCallback((): void => {
    dispatch({ type: "clearAllFilters" });
    setFilterModel({ items: [] });
    storage?.removeItem(STORAGE_KEY);
  }, [dispatch, storage]);

  const slots = React.useMemo(
    () => ({
      toolbar: (props: GridToolbarProps): JSX.Element => (
        <MemorizedToolbar
          {...props}
          toggleFilters={toggleFilters}
          clearFilters={clearFilters}
          isShowHeaderFilters={isShowHeaderFilters}
        />
      ),
      cell: (props: GridCellProps): JSX.Element => (
        <MemorizedCellRow {...props} clickable={!deactivatedMode} />
      ),
      headerFilterCell: (
        props: GridHeaderFilterCellProps & {
          colDef: GridHeaderFilterCellProps["colDef"] & {
            computedWidth: number;
          };
        }
      ): JSX.Element => (
        <MemorizedHeaderFilterCell
          {...props}
          show={isShowHeaderFilters}
          firstRender={firstHeaderRender}
        />
      ),
      columnMenu: (props: GridColumnMenuProps): JSX.Element => (
        <MemorizedColumnMenu {...props} />
      ),
      noResultsOverlay: () => <MemorizedNoRows />,
      noRowsOverlay: () => <MemorizedNoRows />
    }),
    [
      toggleFilters,
      clearFilters,
      isShowHeaderFilters,
      deactivatedMode,
      firstHeaderRender
    ]
  );

  const setArrowColumnLast = useCallback(
    (apiRef: React.MutableRefObject<GridApiPro>): void => {
      const { left, right } = apiRef.current.getPinnedColumns();
      const arrowKey = EmployeeTableHeaders.Arrow;

      if (!right) {
        return;
      }

      if (right.slice(-1)[0] === arrowKey) {
        return;
      }

      apiRef.current.setPinnedColumns({
        left,
        right: [...right.filter(col => col !== arrowKey), arrowKey]
      });
    },
    []
  );

  return (
    <DataGrid
      // API
      apiRef={apiRef}
      // Data
      rows={rows}
      columns={columns}
      rowCount={rowCount}
      // Custom components
      slots={slots}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton"
        }
      }}
      // Sorting
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      // Filtering
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      headerFilters={true}
      // Column visibility
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      // Pagination
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      // Clicking
      getRowClassName={() => (!deactivatedMode ? "cursor-pointer" : "")}
      disableRowSelectionOnClick
      // Selection
      rowSelection
      checkboxSelection={!deactivatedMode}
      // TODO: Should general select, select all database rows
      onRowSelectionModelChange={onRowSelectionModelChange}
      // Pinned columns
      onPinnedColumnsChange={() => setArrowColumnLast(apiRef)}
      // Status
      loading={isLoading || initialState === undefined}
      initialState={{
        ...initialState,
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: [EmployeeTableHeaders.Arrow]
        }
      }}
    />
  );
};

const isSameData = (
  prevProps: EmployeesTableProps,
  nextProps: EmployeesTableProps
): boolean => {
  return (
    prevProps.displayList.employees?.map(emp => emp.id).join() ===
      nextProps.displayList.employees?.map(emp => emp.id).join() &&
    prevProps.deactivatedMode === nextProps.deactivatedMode &&
    prevProps.isLoading === nextProps.isLoading
  );
};

const MemorizedEmployeesTable = memo(EmployeesTable, isSameData);

export default MemorizedEmployeesTable;
