import React, { FunctionComponent, useContext } from "react";

import * as api from "~/api";
import {
  Dropdown,
  DropdownItem,
  DropdownProps
} from "~/components/Dropdown/Dropdown";
import { MutableSessionContext } from "~/lib/context/";
import { displayLanguage } from "~/lib/languages";

interface LanguageDropdownProps
  extends Omit<DropdownProps, "items" | "currentItem" | "onChangeItem"> {
  company?: api.Company;
  language?: string;
  isAdmin?: boolean;
  allLanguages?: boolean;
  onChange: (language: string) => any;
}

export const generateLanguageList = (
  company?: api.Company,
  allLanguages?: boolean,
  isAdmin?: boolean
): DropdownItem[] => {
  const fallbackLanguageList = allLanguages
    ? [
        "en-US",
        "es",
        "vi-VN",
        "ht-HT",
        "ak",
        "ar",
        "bn",
        "fr",
        "hi",
        "km-KH",
        "mh",
        "my",
        "ne",
        "so",
        "sw",
        "tl",
        "yue",
        "zh-CN",
        "zh-TW",
        "ru",
        "uk",
        "rw",
        "pl",
        "ja",
        "lo"
      ]
    : [];
  return (company?.languages ?? fallbackLanguageList).map(language => ({
    id: language,
    text: displayLanguage(language, isAdmin)
  }));
};

export const LanguageDropdown: FunctionComponent<
  LanguageDropdownProps
> = props => {
  const { company, language, isAdmin, allLanguages, onChange, ...restProps } =
    props;
  const { session } = useContext(MutableSessionContext);

  const languages = generateLanguageList(
    company ?? session?.company,
    allLanguages,
    isAdmin
  );

  const onChangeItem = (item: DropdownItem): void => onChange(item.id);

  return (
    <Dropdown
      {...restProps}
      currentId={language}
      items={languages}
      onChangeItem={onChangeItem}
    />
  );
};
