import { StringTranslations } from "./common";
import { Company, Division } from "./companies";
import { Employee } from "./employees";
import { APIResponse, destroy, get, post } from "./lib";
import { User } from "./users";

import { getGlobalData } from "~/lib/globalData";

export type FeatureFlagValue =
  | "attendance_insights"
  | "tl_survey_responses"
  | "mui_data_grid";

interface Features {
  attendance_event_integration?: boolean;
  full_covid_health_statuses: boolean;
  attendance?: boolean;
  communicate?: boolean;
  portal?: boolean;
  visitors?: boolean;
  enabled_flags: FeatureFlagValue[];
}

interface LabelInfo {
  display: StringTranslations;
  color: string;
}

export type LabelInfoMap = { [key: string]: LabelInfo };

export interface Session {
  is_company_admin: boolean;
  user?: User;
  employee?: Employee;
  company?: Company;
  divisions?: Division[];
  all_divisions_count: number;
  labels: LabelInfoMap;
  features: Features;
  permission_sets: string[];
}

export const EMPTY_SESSION: Session = {
  is_company_admin: false,
  features: {
    full_covid_health_statuses: true,
    enabled_flags: []
  },
  labels: {},
  permission_sets: [],
  all_divisions_count: 0
};

interface EmailPassword {
  email: string;
  password: string;
}

interface Email {
  email: string;
}

export interface PasswordToken {
  password: string;
  passwordConfirm: string;
  token: string;
}

export interface VerifyToken {
  token: string;
}

export interface ResetStatus {
  status: string;
}

export interface EmailStatus {
  status: string;
}

/** Syncronously return the current session as embedded in the page, or null. */
export const retrieveGlobalSession = (): Session | null => {
  return getGlobalData<Session>("session");
};

/** Query the server to obtain the current session */
export const retrieveSession = async (options?: {
  employeeSessionToken?: string;
  conversationToken?: string;
}): Promise<APIResponse<Session>> => {
  let headers;
  if (options?.employeeSessionToken) {
    headers = { "X-EmployeeTokenSession": options.employeeSessionToken };
  } else if (options?.conversationToken) {
    headers = { "X-ConversationToken": options.conversationToken };
  }

  return await get("/sessions/", undefined, headers);
};

/** Attempt to log a user in */
export const login = async (
  data: EmailPassword
): Promise<APIResponse<Session>> => {
  return await post("/sessions/auth/", data);
};

/** Attempt to log a user out */
export const logout = async (): Promise<APIResponse<Session>> => {
  return await destroy("/sessions/auth/");
};

/** Send a password reset email */
export const requestPasswordReset = async (
  data: Email
): Promise<APIResponse<ResetStatus>> => {
  return await post("/password_reset/", data);
};

/** Reset a password using a password reset token */
export const confirmPasswordReset = async (
  data: PasswordToken
): Promise<APIResponse<ResetStatus>> => {
  return await post("/password_reset/confirm/", data);
};

export const validateResetToken = async (
  data: VerifyToken
): Promise<APIResponse<ResetStatus>> => {
  return await post("/password_reset/validate_token/", data);
};

/** Validate that an email exists and password is set */
export const validateEmail = async (
  data: Email
): Promise<APIResponse<EmailStatus>> => {
  return await post("/sessions/check-email/", data);
};
