import React, { FunctionComponent } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";
import Mustache from "mustache";

import { DatePickerWidget } from "./DatePickerWidget";

import * as api from "~/api";
import { useLinguiLocale } from "~/lib/hooks";
import Typography from "~/mui-components/Typography/Typography";
import "./DatePicker.scss";

export interface DatePickerMessageProps
  extends Omit<api.DateQuestion, "kind" | "prompts"> {
  templateText: string;
  context: api.TemplateStringContext;
  onChange: (value: api.ISODateTime) => any;
  disabled?: boolean;
  darkMode?: boolean;
  otherAnswerValue?: api.ISODateTime;
  value?: api.ISODateTime;
  maxPastDays?: number;
  maxFutureDays?: number;
}

export const DatePickerMessage: FunctionComponent<DatePickerMessageProps> = ({
  templateText,
  hintRange,
  otherAnswerValue,
  context,
  disabled,
  darkMode,
  onChange,
  value,
  maxPastDays,
  maxFutureDays
}) => {
  const locale = useLinguiLocale();
  const date = value ? DateTime.fromISO(value) : DateTime.local();
  const otherAnswerDate = otherAnswerValue
    ? DateTime.fromISO(otherAnswerValue)
    : undefined;
  let localeDate = "";
  try {
    localeDate = date.setLocale(locale).toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long"
    });
  } catch (e) {
    localeDate = date.setLocale("en-US").toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long"
    });
  }

  return (
    <div className="flex flex-col space-y-3">
      <Typography
        variant="textBubble"
        className={clsx("w-fit", darkMode && "!bg-hs-black-20 text-white")}
      >
        {Mustache.render(templateText, context)}
      </Typography>
      {disabled ? (
        <Typography
          variant="textBubble"
          textBubbleProps={{ teal: true, border: true }}
          className={clsx(
            "w-fit border border-solid border-hs-green bg-hs-green-20 text-sm",
            darkMode && "text-white"
          )}
        >
          {Mustache.render(localeDate, context)}
        </Typography>
      ) : (
        <DatePickerWidget
          className={darkMode ? undefined : "bg-[#dfe2e1]"}
          range={hintRange}
          maxPastDays={maxPastDays}
          maxFutureDays={maxFutureDays}
          otherAnswerValue={otherAnswerDate?.toJSDate()}
          onChange={jsDate => onChange(DateTime.fromJSDate(jsDate).toISO())}
          value={date.toJSDate()}
        />
      )}
    </div>
  );
};
