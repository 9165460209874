import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";

import { DateTime } from "luxon";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  GenericErrorText,
  ToastContext,
  TSButton,
  FeatureDisabled
} from "~common";
import {
  CommunicateMessageList,
  CommunicateCreateEditModal,
  CommunicateSentModal
} from "~communicate";

/** The top-level Communicate pane view */
export const Communicate: FC = () => {
  const { session } = useContext(MutableSessionContext);
  const toast = useContext(ToastContext);

  const [messages, setMessages] = useState<api.ScheduledMessage[]>();
  const [sentMessages, setSentMessages] = useState<api.SentMessage[]>();
  const [groups, setGroups] = useState<api.Group[]>();

  const [sentMessage, setSentMessage] = useState<api.SentMessage>();
  const [messageToEdit, setMessageToEdit] = useState<
    api.ScheduledMessage | undefined | null
  >();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const loadMessages = useCallback(async (): Promise<void> => {
    let response: api.APIResponse<api.ScheduledMessage[]> | undefined;
    try {
      response = await api.retrieveScheduledMessages({ records_limit: 3 });
    } catch {}
    if (response?.ok) {
      setMessages(response.data);
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  }, [toast]);

  const loadSentMessages = useCallback(async (): Promise<void> => {
    let response: api.APIResponse<api.SentMessage[]> | undefined;
    try {
      response = await api.retrieveSentMessages({ records_limit: 3 });
    } catch {}
    if (response?.ok) {
      setSentMessages(response.data);
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  }, [toast]);

  useEffect(() => {
    loadMessages();
    loadSentMessages();
  }, [loadMessages, loadSentMessages]);

  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      if (!session.company) {
        return;
      }
      let response: api.APIResponse<api.Group[]> | undefined;
      try {
        response = await api.retrieveGroups(session.company.id);
      } catch {}

      if (response?.ok) {
        setGroups(response.data);
      } else {
        toast.show({ variant: "danger", message: <GenericErrorText /> });
      }
    };

    loadGroups();
  }, [session.company, toast]);

  const onClickMessage = (message: api.SentMessage): void => {
    const { sent_id } = message;
    if (sent_id) {
      setSentMessage(message);
    } else {
      setMessageToEdit(message);
    }
  };

  const combinedMessages = useMemo(() => {
    if (!messages || !sentMessages) {
      return;
    }
    // Include all scheduled messages for which we don't have a corresponding sent message
    const scheduledMessagesNotSent = messages.filter(
      ({ id }) =>
        !sentMessages.find(({ id: sentScheduledId }) => sentScheduledId === id)
    );
    return [...scheduledMessagesNotSent, ...sentMessages].sort(
      ({ due: dueA }, { due: dueB }) =>
        DateTime.fromISO(dueB).toMillis() - DateTime.fromISO(dueA).toMillis()
    );
  }, [messages, sentMessages]);

  const handleMessageUpdate = (): void => {
    setMessages(undefined);
    setSentMessages(undefined);
    loadMessages();
    loadSentMessages();
  };

  const handleDelete = async (): Promise<void> => {
    if (!messageToEdit?.id) {
      return;
    }
    setShowDeleteConfirmation(false);
    setMessageToEdit(undefined);
    let response: api.APIResponse<{}> | undefined;
    try {
      response = await api.deleteScheduledMessage(messageToEdit.id);
    } catch (error) {}
    if (response?.ok) {
      // Reload messages:
      handleMessageUpdate();

      toast.show({
        variant: "success",
        message: "The message was successfully deleted."
      });
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  };

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        {session.features.communicate ? (
          <>
            <Row>
              <Col className="d-flex justify-content-between align-middle">
                <h1 className="mb-0">Communicate</h1>
                <TSButton
                  variant="primary"
                  onClick={() => setMessageToEdit(null)}
                >
                  Create
                </TSButton>
              </Col>
            </Row>

            <Row>
              <Col>
                <CommunicateMessageList
                  messages={combinedMessages}
                  onClick={onClickMessage}
                  groups={groups}
                />
              </Col>
            </Row>
            {sentMessage && (
              <CommunicateSentModal
                message={sentMessage}
                handleExit={() => setSentMessage(undefined)}
              />
            )}
            {messageToEdit !== undefined && groups && (
              <CommunicateCreateEditModal
                show={!showDeleteConfirmation}
                messageToEdit={messageToEdit}
                pending={
                  messageToEdit !== null &&
                  DateTime.fromISO(messageToEdit.due) < DateTime.now()
                }
                groups={groups}
                handleMessageUpdate={handleMessageUpdate}
                handleExit={() => setMessageToEdit(undefined)}
                handleDeleteConfirmation={() => setShowDeleteConfirmation(true)}
              />
            )}
            <Modal
              className="confirm-delete-modal"
              container={document.getElementById("ts-modal")}
              show={showDeleteConfirmation}
              centered
            >
              <Modal.Body>
                <h1 className="text-danger">
                  Are you sure you want to delete?
                </h1>
                <p>You can't retrieve deleted messages.</p>
              </Modal.Body>
              <Modal.Footer>
                <TSButton
                  variant="link"
                  size="lg"
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  Cancel
                </TSButton>
                <TSButton
                  variant="danger"
                  size="lg"
                  endIcon="icon-trash"
                  onClick={handleDelete}
                >
                  Delete
                </TSButton>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <FeatureDisabled
            learnMoreLink="https://www.teamsense.com/solutions/employee-communication"
            message="Easily message with your entire team with the click of a button. You send through TeamSense, they receive it through text."
            contactLinkSubject="I'd like to add the Communicate feature to my account!"
          />
        )}
      </Container>
    </div>
  );
};
