import { each, includes, isEmpty, isNil, map, transform, values } from "lodash";

import { msalInstance } from "~/lib/auth";
import { getCSRFToken } from "~/lib/cookies";

export enum Method {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT"
}

interface RunArgs {
  path: string;
  method: Method;
  headers?: Headers;
  body?: PreppedBody;
  cache?: RequestCache;
  sendCSRFToken?: boolean;
}

/** Keys and values targeted at a query string (?foo=1&bar=2) */
export interface Query {
  [key: string]: string;
}

/** A collection of HTTP headers */
export interface Headers {
  [key: string]: string;
}

/** Body data to send to the server */
interface Body {
  [key: string]: any;
}

/** Request body that hasn't been prepped for submission to an endpoint. */
type UnpreppedBody = FormData | Body;

/** Request body that has been prepped for submission to an endpoint. */
type PreppedBody = FormData | string;

/** A successful API response */
export interface APISuccessResponse<T extends object> {
  ok: true;
  status: number;
  data: T;
}

/** All errors returned by a single API request */
export interface APIErrors {
  non_field_errors?: string[];

  [key: string]: string[] | undefined;
}

/** An error API response */
export interface APIErrorResponse {
  ok: false;
  status: number;
  errors: APIErrors;
}

/** An arbitrary API response */
export type APIResponse<T extends object> =
  | APISuccessResponse<T>
  | APIErrorResponse;

/** The data in a paginated response */
export interface PaginatedResponse<T extends object> {
  next: string | null;
  previous: string | null;
  results: T[];
  count?: number;
}

/** When retrieving "all results" with a paginated API, use this value */
export const MAX_LIMIT = 100000;

/** When retrieving "all results" with a typeahead UX, use this value */
export const MAX_TYPEAHEAD_LIMIT = 1000;

/**
 * Converts an Object of key/value pairs into a URL encoded query string
 */
export const buildQueryString = (query?: Query): string => {
  if (isEmpty(query)) {
    return "";
  }
  const result = map(
    query,
    (value: string, key: string) => `${key}=${encodeURIComponent(value)}`
  );

  return `?${result.join("&")}`;
};

/**
 * Prepares a body for submission to an endpoint and provides any
 * headers needed in conjunction with the body.
 */
interface PreppedSubmission {
  preppedHeaders: Headers;
  preppedBody: PreppedBody;
}

const prepSubmission = (body: UnpreppedBody): PreppedSubmission => {
  // Must the body be submitted as a FormData instance, or can it be submitted as JSON?
  const formDataReqd = values(body).some(value => value instanceof File);

  if (formDataReqd) {
    // The body must be sumitted as a FormData instance.
    const formData = new FormData();
    each(body, (value, key) => {
      if (!isNil(value)) {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value as string);
        }
      }
    });

    // No special headers are required with a FormData body.
    return { preppedHeaders: {}, preppedBody: formData };
  } else {
    // The body does not need to be submitted as a FormData instance;
    // it can be submitted as JSON. A Content-Type header is required.
    return {
      preppedHeaders: { "Content-Type": "application/json" },
      preppedBody: JSON.stringify(body)
    };
  }
};

/**
 * GET method which automatically encodes the query object
 */
export const get = async <T extends object>(
  path: string,
  query?: Query,
  headers?: Headers,
  cache?: RequestCache
): Promise<APIResponse<T>> => {
  return await run({
    path: `${path}${buildQueryString(query)}`,
    method: Method.GET,
    headers,
    cache,
    sendCSRFToken: false
  });
};

/**
 * POST method which automatically encodes a JSON body
 */
export const post = async <T extends object>(
  path: string,
  body: UnpreppedBody,
  headers?: Headers
): Promise<APIResponse<T>> => {
  if (includes(body, "id")) {
    console.warn("ID already exists, you probably meant to PUT or PATCH");
  }
  const { preppedHeaders, preppedBody } = prepSubmission(body);
  return await run({
    path,
    method: Method.POST,
    headers: { ...headers, ...preppedHeaders },
    body: preppedBody
  });
};

/**
 * PUT method which automatically encodes a body
 */
export const put = async <T extends object>(
  path: string,
  body: UnpreppedBody,
  headers?: Headers
): Promise<APIResponse<T>> => {
  const { preppedHeaders, preppedBody } = prepSubmission(body);
  return await run({
    path,
    method: Method.PUT,
    headers: { ...headers, ...preppedHeaders },
    body: preppedBody
  });
};

/**
 * PATCH method which automatically encodes the body
 */
export const patch = async <T extends object>(
  path: string,
  body: UnpreppedBody
): Promise<APIResponse<T>> => {
  const { preppedHeaders, preppedBody } = prepSubmission(body);
  return await run({
    path,
    method: Method.PATCH,
    headers: preppedHeaders,
    body: preppedBody
  });
};

/**
 * DELETE method (`destroy` since `delete` is a reserved word in JS)
 */
export const destroy = async <T extends object>(
  path: string,
  body?: UnpreppedBody,
  headers?: Headers
): Promise<APIResponse<T>> => {
  const args: RunArgs = {
    path,
    method: Method.DELETE,
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (body) {
    const { preppedHeaders, preppedBody } = prepSubmission(body);
    args.headers = preppedHeaders;
    args.body = preppedBody;
  }
  return await run({
    ...args,
    headers: { ...headers, ...args.headers }
  });
};

/**
 * Convert any top-level 'detail' key to a 'non_field_errors' array, for consistency.
 */
const cleanErrors = (errors: {}): APIErrors =>
  transform(errors, (a, v, k) => {
    if (v && (k === "detail" || k === "status")) {
      a.non_field_errors = [v as string];
    } else {
      a[k] = v as string[] | undefined;
    }
  });

export const buildRequestOptions = async (
  { path, method, headers, body, cache, sendCSRFToken = true }: RunArgs,
  msal = msalInstance()
): Promise<RequestInit> => {
  // Update headers if csrf token is desired
  if (sendCSRFToken) {
    headers = headers ?? {};
    const token = getCSRFToken();
    if (token) {
      headers["X-CSRFToken"] = token;
    } else {
      console.error(
        `api.lib: Unable to get CSRF token for ${method} to ${path}`
      );
    }
  }

  const account = msal.getAllAccounts()[0];

  if (account) {
    const authorization = await msal.acquireTokenSilent({
      account,
      scopes: []
    });
    if (authorization?.idToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${authorization.idToken}`
      };
    }
  }

  // Issue the request
  return {
    body,
    ...(headers ? { headers } : {}), // NOTE: we can't send headers: undefined on iOS 10.3!
    cache,
    method
  };
};
/**
 * Invoke our API and return an APIResponse.
 */
const run = async <T extends object>({
  path,
  method,
  headers,
  body,
  cache,
  sendCSRFToken = true
}: RunArgs): Promise<APIResponse<T>> => {
  const options = await buildRequestOptions({
    path,
    method,
    headers,
    body,
    cache,
    sendCSRFToken
  });

  // Issue the request
  const response = await fetch(`/api${path}`, options);

  // Convert the body data from JSON
  let data;
  try {
    data = await response.json();
  } catch {
    data = null;
  }

  // Return one of the kinds of responses
  if (response.ok) {
    console.debug("api.lib.run: success: ", data);
    return { ok: true, status: response.status, data };
  } else {
    console.debug("api.lib.run: failure: ", data);
    const errors = cleanErrors(data ?? {});
    console.debug("api.lib.run: errors: ", errors);
    return {
      ok: false,
      status: response.status,
      errors
    };
  }
};
