import React from "react";

import { Trans } from "@lingui/react";

export const getDateStrObj = (
  date: Date | number | string | undefined
): { day: string | JSX.Element; time: string } => {
  if (!date) {
    return { day: "", time: "" };
  }
  if (typeof date === "number" || typeof date === "string") {
    date = new Date(date);
  }
  const currentDate = new Date();
  const diff = (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
  let day;
  if (diff < 1 && currentDate.getDate() === date.getDate()) {
    day = <Trans id="attendancePointsBar.header.date.today">Today</Trans>;
  } else if (diff < 2 && currentDate.getDate() - date.getDate() === 1) {
    day = (
      <Trans id="attendancePointsBar.header.date.yesterday">Yesterday</Trans>
    );
  } else {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short"
    };
    day = date.toLocaleDateString("en-US", options);
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short"
  };
  const time = date.toLocaleString("en-US", options);
  return { day, time };
};
