export const displayLanguage = (
  language: string,
  isAdmin?: boolean,
  returnDefault?: boolean
): string => {
  switch (language) {
    case "en-US":
      return "English";
    case "es":
      return isAdmin ? "Spanish (Español)" : "Español";
    case "vi-VN":
      return isAdmin ? "Vietnamese (Tiếng Việt)" : "Tiếng Việt";
    case "ht-HT":
      return isAdmin ? "Creole (Kreyòl)" : "Kreyòl";
    case "ak":
      return isAdmin ? "Akan (əˈkæn)" : "Akan";
    case "ar":
      return isAdmin ? "Arabic (عربي)" : "عربي";
    case "bn":
      return isAdmin ? "Bengali (বাংলা))" : "Bengali";
    case "my":
      return isAdmin ? "Burmese (မြန်မာ)" : "မြန်မာ";
    case "zh-CN":
      return isAdmin ? "Chinese Simplified (中国人)" : "中国人";
    case "zh-TW":
      return isAdmin ? "Chinese Traditional (中國人)" : "中國人";
    case "yue":
      return isAdmin ? "Cantonese Chinese (廣東話)" : "廣東話";
    case "fr":
      return isAdmin ? "French (français)" : "français";
    case "hi":
      return isAdmin ? "Hindi (हिन्दी)" : "हिन्दी";
    case "mh":
      return isAdmin ? "Marshallese (Ebon)" : "Marshallese";
    case "ne":
      return isAdmin ? "Nepali (नेपाली)" : "नेपाली";
    case "so":
      return isAdmin ? "Somali (somaliyeed)" : "somaliyeed";
    case "km-KH":
      return isAdmin ? "Khmer (ខ្មែរ)" : "ខ្មែរ";
    case "sw":
      return isAdmin ? "Swahili (kiswahili)" : "kiswahili";
    case "tl":
      return isAdmin ? "Tagalog (ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔)" : "Tagalog";
    case "ru":
      return isAdmin ? "Russian (русский)" : "русский";
    case "uk":
      return isAdmin ? "Ukrainian (українська)" : "українська";
    case "rw":
      return isAdmin ? "Kinyarwanda (Ikinyarwanda)" : "Ikinyarwanda";
    case "pl":
      return isAdmin ? "Polish (polski)" : "polski";
    case "ja":
      return isAdmin ? "Japanese (日本語)" : "日本語";
    case "lo":
      return isAdmin ? "Lao (ລາວ)" : "ລາວ";
  }
  if (returnDefault) {
    return language;
  }
  return "";
};
