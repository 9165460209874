import * as React from "react";
import { memo, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import {
  CellPropsOverrides,
  GridCell,
  GridCellProps,
  GridColumnMenu,
  GridColumnMenuProps,
  GridHeaderFilterCell,
  GridHeaderFilterCellProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarProps,
  ToolbarPropsOverrides
} from "@mui/x-data-grid-pro";
import { CaretRight } from "@phosphor-icons/react";

import Button from "../Button/Button";
import colors from "../Theme/colors";

import * as api from "~/api";
import {
  getLabelColor,
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";
import { TSPill } from "~/ts-components/common/ts-pill/TSPill";
import Restricted from "~/ts-components/permissionProvider/Restricted";

type CustomToolbarProps = GridToolbarProps &
  ToolbarPropsOverrides & {
    toggleFilters: () => void;
    clearFilters: () => void;
    isShowHeaderFilters: boolean;
  };

const getEmployeeLink = (id: string): string =>
  `/dashboard/new-employees/employee/${id}`;

const CustomToolbar = (props: CustomToolbarProps): JSX.Element => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            variant: "text"
          }
        }}
      />
      <Button
        onClick={() => props.toggleFilters()}
        variant="text"
        sx={{ width: "110px" }}
      >
        {props.isShowHeaderFilters ? "Hide" : "Show"} Filters
      </Button>
      <GridToolbarDensitySelector
        slotProps={{
          button: {
            variant: "text"
          }
        }}
      />
      <Button onClick={() => props.clearFilters()} variant="text">
        Remove all filters
      </Button>
    </GridToolbarContainer>
  );
};

export const MemorizedToolbar = memo(
  ({ toggleFilters, isShowHeaderFilters, ...props }: CustomToolbarProps) => (
    <CustomToolbar
      {...props}
      toggleFilters={toggleFilters}
      isShowHeaderFilters={isShowHeaderFilters}
    />
  ),
  (prevProps, nextProps) =>
    prevProps.isShowHeaderFilters === nextProps.isShowHeaderFilters
);

type CustomCellRowProps = GridCellProps &
  CellPropsOverrides & { clickable?: boolean };

const onClick = (
  e: { preventDefault: () => void },
  callback: () => void
): void => {
  // Preventing the href to work to allow onRowClick to handle the click
  // So the app does an internal redirection instead of a full page reload
  e.preventDefault();
  callback();
};

const CustomCellRow = ({
  clickable = true,
  ...props
}: CustomCellRowProps): JSX.Element => {
  const history = useHistory();
  if (
    !clickable ||
    ["__check__", "team_lead"].includes(props.column.field) ||
    props.pinnedPosition
  ) {
    return <GridCell {...props} />;
  }
  const link = getEmployeeLink(`${props.rowId}`);
  const callback = (): void => history.push(link);
  return (
    <a
      href={link}
      onClick={e => onClick(e, callback)}
      target="_self"
      className="custom-cell"
    >
      <GridCell {...props} />
    </a>
  );
};

export const MemorizedCellRow = memo(
  (props: CustomCellRowProps) => <CustomCellRow {...props} />,
  (prevProps, nextProps) => {
    if (prevProps.column.field === "_arrow") {
      return false;
    }
    return (
      prevProps.rowId === nextProps.rowId &&
      prevProps.width === nextProps.width &&
      prevProps.colIndex === nextProps.colIndex
    );
  }
);

type CustomCellLinkProps = { id: string; name: string };

const CustomCellLink = ({ id, name }: CustomCellLinkProps): JSX.Element => {
  const history = useHistory();
  const link = getEmployeeLink(id);
  const callback = (): void => history.push(link);
  return (
    <a
      href={link}
      onClick={e => onClick(e, callback)}
      target="_self"
      className="custom-cell"
    >
      {name}
    </a>
  );
};

export const MemorizedCellLink = memo(
  (props: CustomCellLinkProps) => <CustomCellLink {...props} />,
  (prevProps, nextProps) => prevProps.id === nextProps.id
);

const CustomColumnMenu = (props: GridColumnMenuProps): JSX.Element => {
  return <GridColumnMenu {...props} slots={{ columnMenuFilterItem: null }} />;
};

export const MemorizedColumnMenu = memo(
  (props: GridColumnMenuProps) => <CustomColumnMenu {...props} />,
  () => true
);

type CustomHeaderFilterCellProps = GridHeaderFilterCellProps & {
  show: boolean;
  firstRender: boolean;
  colDef: GridHeaderFilterCellProps["colDef"] & { computedWidth: number };
};

const CustomHeaderFilterCell = ({
  show,
  firstRender,
  ...props
}: CustomHeaderFilterCellProps): JSX.Element => {
  const [showAnimation, setShowAnimation] = useState(!firstRender && !show);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowAnimation(show);
    }, 0);
  }, [show]);

  return (
    <Collapse in={showAnimation}>
      <GridHeaderFilterCell {...props} />
    </Collapse>
  );
};

export const MemorizedHeaderFilterCell = memo(
  (props: CustomHeaderFilterCellProps) => <CustomHeaderFilterCell {...props} />,
  (prevProps, nextProps) =>
    prevProps.show === nextProps.show &&
    prevProps.colDef.computedWidth === nextProps.colDef.computedWidth
);

const RightArrow = (): JSX.Element => (
  <div
    style={{
      cursor: "pointer",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <CaretRight size={20} color={colors.teal[40]} />
  </div>
);

export const MemorizedRightArrow = memo(
  () => <RightArrow />,
  () => true
);

type StatusPillsProps = {
  labels: api.LabelInfoMap;
  language: string;
  activeEvents: api.EmployeeEvent[];
  employee: api.Employee;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => void;
};

const StatusPills = ({
  activeEvents,
  labels,
  language,
  employee,
  onEditStatusClick
}: StatusPillsProps): JSX.Element => {
  const statusPills = activeEvents.map(({ label: { name } }) => (
    <Restricted
      to={[
        "EmployeeHealthStatus.READ",
        "EmployeeHealthStatus.UPDATE",
        "EmployeeHealthStatus.CREATE",
        "EmployeeHealthStatus.DELETE"
      ]}
      key={name}
      disabled
    >
      <TSPill
        key={name}
        text={getLabelDisplay(name, labels, language)}
        background={getLabelColorLightened(name, labels)}
        color={getLabelColorDarkened(name, labels)}
        backgroundHover={getLabelColor(name, labels)}
        button
        onClick={event => {
          event.stopPropagation();
          onEditStatusClick?.(employee, name);
        }}
      />
    </Restricted>
  ));
  return <>{statusPills}</>;
};

export const MemorizedStatusPills = memo(
  (props: StatusPillsProps) => <StatusPills {...props} />,
  () => true
);

type ReactivateButtonProps = {
  employee: api.Employee;
  onReactivateClick?: (employee: api.Employee) => void;
};

const ReactivateButton = ({
  employee,
  onReactivateClick
}: ReactivateButtonProps): JSX.Element => {
  if (!employee) {
    return <td />;
  }
  return (
    <Restricted to="EmployeeReactivate.CREATE">
      <td className="flex w-full h-full justify-center items-center">
        <Button variant="text" onClick={() => onReactivateClick?.(employee)}>
          Reactivate
        </Button>
      </td>
    </Restricted>
  );
};

export const MemorizedReactivateButton = memo(
  (props: ReactivateButtonProps) => <ReactivateButton {...props} />,
  () => true
);

const NoRows = (): JSX.Element => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={96}
      viewBox="0 0 523 299"
      aria-hidden
      focusable="false"
    >
      <path
        className="no-results-primary"
        fill="#767E7D"
        d="M262 20c-63.513 0-115 51.487-115 115s51.487 115 115 115 115-51.487 115-115S325.513 20 262 20ZM127 135C127 60.442 187.442 0 262 0c74.558 0 135 60.442 135 135 0 74.558-60.442 135-135 135-74.558 0-135-60.442-135-135Z"
      />
      <path
        className="no-results-primary"
        fill="#767E7D"
        d="M348.929 224.929c3.905-3.905 10.237-3.905 14.142 0l56.569 56.568c3.905 3.906 3.905 10.237 0 14.143-3.906 3.905-10.237 3.905-14.143 0l-56.568-56.569c-3.905-3.905-3.905-10.237 0-14.142ZM212.929 85.929c3.905-3.905 10.237-3.905 14.142 0l84.853 84.853c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0l-84.853-84.853c-3.905-3.905-3.905-10.237 0-14.142Z"
      />
      <path
        className="no-results-primary"
        fill="#767E7D"
        d="M212.929 185.071c-3.905-3.905-3.905-10.237 0-14.142l84.853-84.853c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-84.853 84.853c-3.905 3.905-10.237 3.905-14.142 0Z"
      />
      <path
        className="no-results-secondary"
        fill="#BFC5C4"
        d="M0 43c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 53 0 48.523 0 43ZM0 89c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 99 0 94.523 0 89ZM0 135c0-5.523 4.477-10 10-10h74c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 181c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 227c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM523 227c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10ZM523 181c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 135c0 5.523-4.477 10-10 10h-74c-5.523 0-10-4.477-10-10s4.477-10 10-10h74c5.523 0 10 4.477 10 10ZM523 89c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 43c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10Z"
      />
    </svg>
    <div className="flex flex-col gap-2 justify-center items-center">
      <Typography variant="h1" className="text-ts-gray-20">
        No results found
      </Typography>
      <Typography variant="h3" className="text-ts-gray-20">
        We couldn't find any records that met your criteria.
      </Typography>
      <Typography variant="h3" className="text-ts-gray-20">
        Try other filters or a different search.
      </Typography>
    </div>
  </>
);

export const MemorizedNoRows = memo(
  () => <NoRows />,
  () => true
);
