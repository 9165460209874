import React, { FunctionComponent, useContext } from "react";

import { DateTime } from "luxon";

import "./CommunicateMessageRow.scss";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  generateRecipientDisplayText,
  parseRecipients
} from "~/lib/recipientsUtils";
import { TSPill } from "~common";

export const CommunicateMessageRow: FunctionComponent<
  CommunicateMessageRowProps
> = ({ message, groups, onClick }) => {
  const { session } = useContext(MutableSessionContext);

  // time data
  const dt = DateTime.fromISO(message.due);
  const now = DateTime.now();

  let shortDate = "";
  let simpleTime = "";

  try {
    shortDate = dt.toLocaleString(DateTime.DATE_SHORT);
    simpleTime = dt.toLocaleString(DateTime.TIME_SIMPLE);
  } catch {
    shortDate = dt.toLocaleString({ ...DateTime.DATE_SHORT, locale: "en-US" });
    simpleTime = dt.toLocaleString({
      ...DateTime.TIME_SIMPLE,
      locale: "en-US"
    });
  }

  // pill data
  const status = "sent_at" in message ? "sent" : "scheduled";
  let statusText;
  let statusVariant;

  if (status === "scheduled") {
    if (dt < now) {
      statusText = "Pending";
      statusVariant = "warning";
    } else {
      statusText = "Scheduled";
      statusVariant = "info";
    }
  } else {
    statusText = "Sent";
    statusVariant = "light";
  }

  // recipients data
  const recipients = message?.recipients_json;
  const parsedRecipients = parseRecipients(recipients, session, groups);
  const notParsedRecipientsNumber = recipients.length - parsedRecipients.length;
  const displayText = generateRecipientDisplayText(
    parsedRecipients,
    notParsedRecipientsNumber
  );

  // message data
  const subject = message.context_json.email_subject;
  const body = message.context_json.email_body;

  // created data
  const creatorName = message.created_by.name;
  const creatorExternalId = message.created_by.external_id;

  return (
    <tr
      key={message.id}
      onClick={() => onClick(message)}
      className="communicate-row"
    >
      <td>
        <TSPill text={statusText} variant={statusVariant} />
      </td>
      <td className="td-send-date">
        <h3>{shortDate}</h3>
        <h6>{simpleTime}</h6>
      </td>
      <td>
        <h3>{displayText}</h3>
      </td>
      <td className="td-message">
        <h3>{subject}</h3>
        <h5>{body}</h5>
      </td>
      <td>
        <h3>{creatorName}</h3>
        <h6>ID: {creatorExternalId}</h6>
      </td>
    </tr>
  );
};

interface CommunicateMessageRowProps {
  message: api.ScheduledMessage | api.SentMessage;
  onClick: (message: api.SentMessage) => any;
  groups?: api.Group[];
}
